// Angular imports
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

// Util imports
import { Utils } from '../../../_utils/utils';

// Component imports
import { BaseComponent } from '../../../_base/base.component';

// Service imports
import { OrganizationService } from '../../../_services/organization.service';

// Type imports
import { SelectionGridEvent } from '../../../_types/selection-grid-event';

// Model imports
import { BaseQueryParameterDto } from '../../../_models/_base/base-query-parameter.dto';
import { OrganizationResultDto } from '../../../_models/organization/organization-result.dto';

@Component({
  selector: 'app-organization-selector',
  templateUrl: './organization-selector.component.html',
  styleUrls: ['./organization-selector.component.scss']
})
export class OrganizationSelectorComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input() readOnly = false;
  @Input() isRequired = false;
  @Input() validationGroup: string;
  @Input() selectedKeys: Array<number> = [];
  @Output() onSelectionChanged = new EventEmitter<
    SelectionGridEvent<OrganizationResultDto, number>
  >();

  organizations: Array<OrganizationResultDto>;

  private readonly organizationService: OrganizationService;

  constructor(protected override readonly injector: Injector) {
    super(injector);
    this.organizationService = injector.get(OrganizationService);
  }

  ngOnInit(): void {
    this.onInit('OrganizationSelectorComponent');
    this.populateOrganizations();
  }

  ngOnDestroy(): void {
    this.onDestroy('OrganizationMenuComponent');
  }

  onContentReady(): void {
    if (Utils.notNullOrEmpty(this.selectedKeys)) {
      const records = this.organizations.filter((r) =>
        Utils.isInList(r.organizationId, this.selectedKeys)
      );
      const e: SelectionGridEvent<OrganizationResultDto, number> = {
        selectedKeys: this.selectedKeys,
        selectedRows: records
      };
      this.onSelectionChanged.emit(e);
    }
  }

  onValueChanged(e: SelectionGridEvent<OrganizationResultDto, number>): void {
    this.selectedKeys = e.selectedKeys;
    this.onSelectionChanged.emit(e);
  }

  private populateOrganizations(): void {
    this.organizationService
      .getOrganizations(new BaseQueryParameterDto())
      .subscribe((results: Array<OrganizationResultDto>) => {
        if (Utils.isNullOrUndefined(this.organizationInfo)) {
          this.organizationInfo = results.find((r) =>
            Utils.equals(r.organizationId, this.userInfo.organizationId)
          );
        }
        this.organizations = results;
      });
  }
}

<div *ngIf="!!dataSource" class="cv-fullsize-content">
  <dx-data-grid
    appDataGridFeatures
    width="100%"
    height="100%"
    keyExpr="recordId"
    [gridFeatures]="{
      headerFilter: { visible: false },
      filterRow: { visible: false }
    }"
    [dataSource]="dataSource"
  >
    <dxi-column
      type="actions"
      caption="Actions"
      cellTemplate="actionsCellTemplate"
      [visible]="!isReadOnly"
    >
      <div
        *dxTemplate="let cell of 'actionsCellTemplate'"
        class="cv-actions-cell-template"
      >
        <app-actions-cell-template
          [record]="cell.data"
          [rowIndex]="cell.rowIndex"
          [actionItems]="actionItems"
          [isActionVisible]="isActionVisible"
          (onActionClick)="onActionClick($event)"
        ></app-actions-cell-template>
      </div>
    </dxi-column>
    <dxi-column
      dataField="generatedByText"
      dataType="string"
      caption="Generated By"
    ></dxi-column>
    <dxi-column
      dataField="generatedDate"
      dataType="date"
      [format]="dateTimeFormat"
    ></dxi-column>
    <dxi-column
      dataField="recipientText"
      dataType="string"
      caption="Recipients"
      cellTemplate="linkCellTemplate"
    ></dxi-column>
    <dxi-column
      [width]="120"
      dataField="includeCoverLetter"
      dataType="boolean"
      caption="Has Cover Letter"
      cellTemplate="checkCellTemplate"
    ></dxi-column>
    <dxi-column
      dataField="jobStatus"
      dataType="string"
      caption="Status"
      cellTemplate="statusCellTemplate"
    ></dxi-column>
    <div *dxTemplate="let cell of 'linkCellTemplate'">
      <app-link-cell-template
        [displayText]="cell.value"
        (onClick)="onLinkCellClick(cell.data)"
      ></app-link-cell-template>
    </div>
    <div *dxTemplate="let cell of 'checkCellTemplate'">
      <app-check-cell-template
        [value]="cell.value"
        [tooltipText]="cell.column.caption"
      ></app-check-cell-template>
    </div>
    <div *dxTemplate="let cell of 'statusCellTemplate'">
      <app-status-cell-template
        [status]="cell.value"
      ></app-status-cell-template>
    </div>
  </dx-data-grid>
</div>

<app-event-notices-status
  *ngIf="showStatus"
  title="Paper Communication Status"
  [isEmailStatus]="false"
  [dataSource]="statusResults"
  (onClose)="showStatus = false"
></app-event-notices-status>

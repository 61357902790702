// Angular imports
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

// Dx imports
import { DxFormComponent } from 'devextreme-angular/ui/form';

// Util imports
import { DxWidgetUtils } from '../../../_utils/dx-widget.utils';
import { Utils } from '../../../_utils/utils';

// Component imports
import { BaseComponent } from '../../../_base/base.component';

// Constant imports
import { ActionConstants } from '../../../_constants/action.constants';
import { IconConstants } from '../../../_constants/icon.constants';

// Enum imports
import { CompositeReportType } from '../../../_enums/composite-report-type.enum';

// Type imports
import { dxToolbarItem } from '../../../_types/dx-toolbar-item';

// Service imports
import { CompositeReportsPackageService } from '../../../_types/composite-reports-package-service';

// Model imports
import { FileInfoParameterDto } from '../../../_models/common/file-info-parameter.dto';
import { BaseEventParameterDto } from '../../../_models/event/base-event-parameter.dto';
import { BaseEventReportsPackageParameterDto } from '../../../_models/event/base-event-reports-package-parameter.dto';
import { EventReportsPackageParameterDto } from '../../../_models/event/event-reports-package-parameter.dto';
import { EventReportsPackageResultDto } from '../../../_models/event/event-reports-package-result.dto';

@Component({
  selector: 'app-event-reports-package',
  templateUrl: './event-reports-package.component.html',
  styleUrls: ['./event-reports-package.component.scss']
})
export class EventReportsPackageComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild('editorForm', { static: false }) editorForm: DxFormComponent;

  @Input() title: string;
  @Input() filename: string;
  @Input() reportType = CompositeReportType.All;
  @Input() organizationId: number;
  @Input() entityId: number;
  @Input() eventId: number;
  @Input() isReadOnly: boolean;
  @Input() dashboardPdfBase64: string;
  @Input() apiService: CompositeReportsPackageService;
  @Output() onClose = new EventEmitter<void>();

  iconPlus = IconConstants.PLUS;
  iconRemove = IconConstants.REMOVE;
  validationGroup = `event-reports-package`;

  actionButtons: Array<dxToolbarItem>;
  editorResult: EventReportsPackageResultDto;
  editorParam: EventReportsPackageParameterDto;
  additionalDocuments: Array<FileInfoParameterDto> = [];

  constructor(protected override readonly injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.populateReportsPackage();
  }

  onAddFileClick(index: number): void {
    this.editorParam.additionalDocuments.splice(
      index + 1,
      0,
      new FileInfoParameterDto()
    );
    // Update the additionalDocuments to repaint UI
    this.updateAdditionalDocuments();
  }

  onRemoveFileClick(index: number): void {
    const filteredDocuments = this.editorParam.additionalDocuments.filter((r) =>
      Utils.notEqualsIgnoreCase(r.crudAction, ActionConstants.DELETE)
    );
    const record = filteredDocuments[index];
    if (Utils.notNullOrEmpty(record.fileId)) {
      record.crudAction = ActionConstants.DELETE;
    } else {
      this.editorParam.additionalDocuments.splice(index, 1);
    }
    filteredDocuments.splice(index, 1);
    if (Utils.isNullOrEmpty(filteredDocuments)) {
      this.onAddFileClick(0);
    } else {
      // Update the additionalDocuments to repaint UI
      this.updateAdditionalDocuments();
    }
  }

  onActionClick(name: string): void {
    switch (name) {
      case ActionConstants.CLOSE:
        this.onClose.emit();
        break;
      case ActionConstants.REFRESH:
        this.populateReportsPackage();
        break;
      case ActionConstants.DOWNLOAD:
        this.downloadPackage();
        break;
      case ActionConstants.GENERATE:
        this.scheduleGeneration();
        break;
    }
  }

  private populateReportsPackage(): void {
    const params = this.getUpdatedParams(
      new BaseEventReportsPackageParameterDto()
    );
    params.compositeReportType = this.reportType;
    this.apiService
      .getReportsPackage(params)
      .subscribe((result: EventReportsPackageResultDto) => {
        result.reportFilename = result.reportFilename || this.filename;
        this.editorResult = result;
        this.editorParam = this.getUpdatedParams(result.toParameter());
        this.editorParam.compositeReportType = this.reportType;
        this.editorParam.dashboardPdfBase64 = this.dashboardPdfBase64;
        this.updateAdditionalDocuments();
        this.updateActionButtons();
      });
  }

  private downloadPackage(): void {
    const params = this.getUpdatedParams(
      new BaseEventReportsPackageParameterDto()
    );
    params.compositeReportType = this.reportType;
    this.apiService.downloadPackage(params);
  }

  private scheduleGeneration(): void {
    if (DxWidgetUtils.isFormValid(this.editorForm)) {
      this.apiService.schedulePackage(this.editorParam).subscribe(() => {
        this.showSuccess(`${this.title} Scheduled Successfully`);
        this.populateReportsPackage();
      });
    }
  }

  private updateActionButtons(): void {
    this.actionButtons = [
      {
        name: ActionConstants.CLOSE,
        location: 'before',
        options: {
          type: 'default',
          text: 'Close',
          hint: 'Close the popup',
          icon: IconConstants.CLOSE
        }
      },
      {
        name: ActionConstants.REFRESH,
        location: 'after',
        options: {
          type: 'default',
          text: 'Refresh',
          hint: 'Refresh Status',
          icon: IconConstants.REFRESH
        }
      },
      {
        name: ActionConstants.DOWNLOAD,
        location: 'after',
        options: {
          type: 'default',
          text: ActionConstants.DOWNLOAD,
          hint: 'Download PDF Package',
          icon: IconConstants.DOWNLOAD
        }
      },
      {
        name: ActionConstants.GENERATE,
        location: 'after',
        options: {
          type: 'success',
          text: Utils.isTrue(this.editorResult.allowDownload)
            ? ActionConstants.REGENERATE
            : ActionConstants.GENERATE,
          hint: 'Save & Generate PDF Package',
          icon: IconConstants.INPROGRESS
        }
      }
    ].filter(
      (r) =>
        Utils.equalsIgnoreCase(r.name, ActionConstants.CLOSE) ||
        (Utils.equalsIgnoreCase(r.name, ActionConstants.REFRESH) &&
          Utils.isFalse(this.editorResult.allowGenerate)) ||
        (Utils.equalsIgnoreCase(r.name, ActionConstants.DOWNLOAD) &&
          Utils.isTrue(this.editorResult.allowDownload)) ||
        (Utils.equalsIgnoreCase(r.name, ActionConstants.GENERATE) &&
          Utils.isTrue(this.editorResult.allowGenerate))
    ) as Array<dxToolbarItem>;
  }

  private updateAdditionalDocuments(): void {
    // Update the additionalDocuments to repaint UI
    const filteredDocuments = this.editorParam.additionalDocuments.filter((r) =>
      Utils.notEqualsIgnoreCase(r.crudAction, ActionConstants.DELETE)
    );
    const deletedDocuments = this.editorParam.additionalDocuments.filter((r) =>
      Utils.equalsIgnoreCase(r.crudAction, ActionConstants.DELETE)
    );
    this.editorParam.additionalDocuments = [
      ...deletedDocuments,
      ...filteredDocuments
    ];
    this.additionalDocuments = [...filteredDocuments];
    setTimeout(
      () =>
        (this.editorForm.instance as any)._scrollable.scrollBy(
          64 * (this.additionalDocuments.length + 1)
        ),
      100
    );
  }

  private getUpdatedParams<T extends BaseEventParameterDto>(params: T): T {
    params.organizationId = this.organizationId;
    params.entityId = this.entityId;
    params.eventId = this.eventId;
    return params;
  }
}

import { Transform } from 'class-transformer';
import { BaseCommunityParameterDto } from '../../../_models/community/base-community-parameter.dto';
import { GeoLocationUtils } from '../../../_utils/geo-location.utils';

export class AddPropertyParticipantParameterDto extends BaseCommunityParameterDto {
  @Transform(GeoLocationUtils.toJsonCityId, { toPlainOnly: true })
  cityId: number;
  stateId: number;
  ownerNumber: number = null;
  propertyId: number;
  propertyParticipantTypeId: number;
  weightage: number;

  email: string;
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  cityName: string;
  postalCode: string;
  homePhone: string;
  cellPhone: string;
  secondaryEmail: string;
  secondaryPhone: string;
  customerId: string;
}

<app-selection-grid
  *ngIf="!!communities"
  name="Community"
  keyExpr="id"
  valueExpr="id"
  displayExpr="name"
  placeholder="Select a Community"
  [readOnly]="readOnly"
  [isRequired]="isRequired"
  [showClearButton]="!isRequired"
  [validationGroup]="validationGroup"
  [dropDownOptions]="{ minWidth: '600px' }"
  [dataSource]="communities"
  [value]="selectedKeys"
  [columns]="[
    {
      dataField: 'id',
      dataType: 'number',
      caption: 'Id',
      visible: false
    },
    {
      dataField: 'referredName',
      dataType: 'string',
      caption: 'Name'
    },
    {
      dataField: 'legalName',
      dataType: 'string',
      caption: 'Legal Name'
    },
    {
      dataField: 'addressText',
      dataType: 'string',
      caption: 'Address'
    },
    {
      dataField: 'communityType',
      dataType: 'string',
      caption: 'Type'
    }
  ]"
  (onValueChanged)="onValueChanged($event)"
  (onContentReady)="onContentReady()"
></app-selection-grid>

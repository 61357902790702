export enum ReferenceDateType {
  EventCloseDate = 1,
  ParticipationEndDate = 2,
  ParticipationStartDate = 3,
  CustomDate = 4
}

export enum ReferenceDateTypeLabel {
  EventCloseDate = `Event Close Date`,
  ParticipationEndDate = `Participation End Date`,
  ParticipationStartDate = `Participation Start Date`,
  CustomDate = `Custom Date`
}

import { BaseCommunityParameterDto } from '../community/base-community-parameter.dto';

export class BaseEventParameterDto extends BaseCommunityParameterDto {
  eventId: number;

  toBaseParameter(): BaseEventParameterDto {
    const params = new BaseEventParameterDto();
    params.organizationId = this.organizationId;
    params.entityId = this.entityId;
    params.eventId = this.eventId;
    params.clearCache = this.clearCache;
    return params;
  }
}

<app-selection-grid
  *ngIf="!!properties"
  name="Property"
  keyExpr="propertyId"
  valueExpr="propertyId"
  displayExpr="streetAddress"
  placeholder="Select a Property"
  [readOnly]="readOnly"
  [isRequired]="isRequired"
  [showClearButton]="!isRequired"
  [validationGroup]="validationGroup"
  [dropDownOptions]="{ minWidth: '600px' }"
  [dataSource]="properties"
  [value]="selectedKeys"
  [columns]="[
    {
      dataField: 'propertyId',
      dataType: 'number',
      caption: 'PropertyId',
      visible: false
    },
    {
      dataField: 'streetAddress',
      dataType: 'string',
      caption: 'Street Address'
    },
    {
      dataField: 'addressText',
      dataType: 'string',
      caption: 'Address'
    },
    {
      dataField: 'propertyTypeId',
      dataType: 'string',
      caption: 'Type',
      lookup: {
        valueExpr: 'key',
        displayExpr: 'displayText',
        dataSource: propertyTypes
      }
    }
  ]"
  (onValueChanged)="onValueChanged($event)"
  (onContentReady)="onContentReady()"
></app-selection-grid>

// Angular imports
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

// Util imports
import { Utils } from '../../../_utils/utils';

// Component imports
import { BaseComponent } from '../../../_base/base.component';

// Constant imports
import { IconConstants } from '../../../_constants/icon.constants';

// Model imports
import { CitySearchParameterDto } from '../../../_models/geolocation/city-search-parameter.dto';
import { CitySelectorResultDto } from '../../../_models/geolocation/city-selector-result.dto';

@Component({
  selector: 'app-city-selector',
  templateUrl: './city-selector.component.html',
  styleUrls: ['./city-selector.component.scss']
})
export class CitySelectorComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() visible = false;
  @Input() allowUnknown = false;
  @Input() selectedId: number;
  @Input() searchTerm: string;
  @Output() onSearch = new EventEmitter<Array<CitySelectorResultDto>>();
  @Output() onSelect = new EventEmitter<CitySelectorResultDto>();
  @Output() onClose = new EventEmitter<void>();

  iconAdd = IconConstants.PLUS;
  iconSearch = IconConstants.SEARCH;

  showAddCity = false;

  selectedCity: CitySelectorResultDto;
  searchParam: CitySearchParameterDto;
  cities: Array<CitySelectorResultDto>;
  selectedKeys: Array<number> = [];

  addedCities: Array<CitySelectorResultDto>;

  constructor(protected override readonly injector: Injector) {
    super(injector);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onAddCityClick = this.onAddCityClick.bind(this);
  }

  ngOnInit(): void {
    this.cities = [];
    this.addedCities = [];
    this.searchParam = new CitySearchParameterDto();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (Utils.notNullAndDefined(changes['searchTerm'])) {
      this.searchParam = new CitySearchParameterDto();
      if (Utils.notNullOrEmpty(this.searchTerm)) {
        this.searchParam.searchTerm = this.searchTerm;
        this.onSearchClick();
      }
    }
  }

  onSearchClick(): void {
    if (
      Utils.notNullOrEmpty(this.searchParam.searchTerm) &&
      this.searchParam.searchTerm.trim().length > 2
    ) {
      this.geoLocationService
        .searchCities(this.searchParam)
        .subscribe((results: Array<CitySelectorResultDto>) => {
          this.cities = results
            .filter((r) => this.allowUnknown || r.cityId > 0)
            .concat(this.addedCities);
          const selectedCity = this.cities.find((r) =>
            Utils.equals(r.cityId, this.selectedId)
          );
          if (Utils.notNullAndDefined(selectedCity)) {
            this.selectedCity = selectedCity;
            this.onSelect.emit(selectedCity);
          }
          this.onSearch.emit(this.cities);
        });
    }
  }

  onSelectClick(row: CitySelectorResultDto): void {
    this.selectedCity = row;
    this.selectedId = row.cityId;
    this.onSelect.emit(row);
  }

  onHidden(): void {
    if (
      Utils.notInList(
        this.selectedId,
        this.cities.map((r) => r.cityId)
      ) &&
      Utils.notNullAndDefined(this.selectedCity)
    ) {
      this.onSelect.emit(this.selectedCity);
    }
    this.onClose.emit();
  }

  onAddCityClick(): void {
    this.showAddCity = true;
  }

  onAddCityOk(rec: CitySelectorResultDto): void {
    this.addedCities.push(rec);
    this.cities.push(rec);
    this.onSelectClick(rec);
    this.showAddCity = false;
  }
}

<app-page-popup
  *ngIf="!!editorParam"
  maxWidth="80%"
  [title]="title"
  [visible]="true"
  [fullScreen]="fullScreen"
  [buttons]="buttonOptions"
  (onHidden)="onCancel.emit()"
  (onButtonClick)="onButtonClick($event)"
>
  <dx-form
    #editorForm
    width="100%"
    height="100%"
    [readOnly]="readOnly"
    [scrollingEnabled]="true"
    [formData]="editorParam"
  >
    <dxi-item dataField="subject" [isRequired]="!readOnly">
      <dxo-label text="Subject"></dxo-label>
    </dxi-item>
    <dxi-item dataField="htmlContent" [isRequired]="!readOnly">
      <dxo-label text="Content"></dxo-label>
      <div *dxTemplate>
        <app-html-editor
          height="325px"
          [readOnly]="readOnly"
          [fields]="mergeFields"
          [(value)]="editorParam.htmlContent"
          (onFullScreenChanged)="fullScreen = $event"
        ></app-html-editor>
      </div>
    </dxi-item>
    <dxi-item
      [colSpan]="2"
      [isRequired]="!readOnly"
      [visible]="!!recipients.length || allowEmailEntry"
    >
      <dxo-label text="Recipients" location="left"></dxo-label>
      <div *dxTemplate>
        <dx-tag-box
          #recipientsBox
          placeholder="Communication Recipients"
          valueExpr="propertyParticipantId"
          [displayExpr]="allowEmailEntry ? 'email' : 'name'"
          [maxDisplayedTags]="7"
          [multiline]="false"
          [searchEnabled]="true"
          [acceptCustomValue]="allowEmailEntry"
          [showSelectionControls]="true"
          [dataSource]="recipients"
          [buttons]="[
            {
              name: 'search',
              location: 'after',
              options: {
                type: 'default',
                stylingMode: 'text',
                hint: 'Select Recipients',
                icon: iconSearch,
                visible: !!recipients.length,
                onClick: onSearchClick
              }
            }
          ]"
          [(value)]="selectedIds"
          (onValueChanged)="onRecipientsChanged($event)"
          (onCustomItemCreating)="onCustomItemCreating($event)"
        ></dx-tag-box>
      </div>
    </dxi-item>
  </dx-form>
</app-page-popup>

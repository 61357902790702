// Angular imports
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

// Dx imports
import { DxFormComponent } from 'devextreme-angular/ui/form';

// Util imports
import { DxWidgetUtils } from '../../../_utils/dx-widget.utils';
import { Utils } from '../../../_utils/utils';

// Component imports
import { BaseComponent } from '../../../_base/base.component';

// Constant imports
import { ActionConstants } from '../../../_constants/action.constants';

// Model imports
import { BaseQueryParameterDto } from '../../../_models/_base/base-query-parameter.dto';
import { CitySelectorResultDto } from '../../../_models/geolocation/city-selector-result.dto';
import { CountryResultDto } from '../../../_models/geolocation/country-result.dto';
import { StateParameterDto } from '../../../_models/geolocation/state-parameter.dto';
import { StateResultDto } from '../../../_models/geolocation/state-result.dto';

@Component({
  selector: 'app-country-state-selector',
  templateUrl: './country-state-selector.component.html',
  styleUrls: ['./country-state-selector.component.scss']
})
export class CountryStateSelectorComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild('editorForm', { static: false }) editorForm: DxFormComponent;

  @Input() visible = false;
  @Input() cityName: string;
  @Output() onOk = new EventEmitter<CitySelectorResultDto>();
  @Output() onCancel = new EventEmitter<void>();

  countries: Array<CountryResultDto>;
  statesOfCountry: Array<StateResultDto>;

  editorResult: CitySelectorResultDto;

  constructor(protected override readonly injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.editorResult = new CitySelectorResultDto();
    this.editorResult.cityId = Math.ceil(Math.random() * 1000) * -1;
    this.editorResult.cityName = this.cityName;
    this.populateCountries();
  }

  onCountryChanged(e: any): void {
    if (Utils.notNullOrEmpty(e.value)) {
      this.editorResult.countryName = this.countries.find((r) =>
        Utils.equals(r.countryId, e.value)
      ).countryName;
      this.populateStatesOfCountry(e.value);
    }
  }

  onStateChanged(e: any): void {
    if (Utils.notNullOrEmpty(e.value)) {
      this.editorResult.stateName = this.statesOfCountry.find((r) =>
        Utils.equals(r.stateId, e.value)
      ).stateName;
    }
  }

  onButtonClick(name: string): void {
    if (
      Utils.equalsIgnoreCase(name, ActionConstants.OK) &&
      DxWidgetUtils.isFormValid(this.editorForm)
    ) {
      this.onOk.emit(this.editorResult);
    } else if (Utils.equalsIgnoreCase(name, ActionConstants.CANCEL)) {
      this.onCancel.emit();
    }
  }

  onHidden(): void {
    this.onCancel.emit();
  }

  private populateCountries(): void {
    this.geoLocationService
      .getCountries(new BaseQueryParameterDto())
      .subscribe(
        (results: Array<CountryResultDto>) =>
          (this.countries = results.filter((r) => r.countryId > 0))
      );
  }

  private populateStatesOfCountry(countryId: number): void {
    const params = new StateParameterDto();
    params.countryId = countryId;
    this.geoLocationService
      .getStatesOfCountry(params)
      .subscribe(
        (results: Array<StateResultDto>) =>
          (this.statesOfCountry = results.filter((r) => r.stateId > 0))
      );
  }
}

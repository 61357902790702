export enum RecipientGroupType {
  AllParticipants = 1,
  VotedParticipants = 2,
  NotVotedParticipants = 3
}

export enum RecipientGroupTypeLabel {
  AllParticipants = `All Participants`,
  VotedParticipants = `Participants who have voted`,
  NotVotedParticipants = `Participants who have not voted`
}

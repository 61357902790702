<app-page-popup
  [title]="title"
  [visible]="true"
  [buttons]="['Cancel', 'OK']"
  [minHeight]="350"
  (onHidden)="onReasonAction('Cancel')"
  (onButtonClick)="onReasonAction($event)"
>
  <dx-form #editorForm width="100%" height="100%">
    <dxi-item [visible]="showContent">
      <div *dxTemplate>
        <ng-content></ng-content>
      </div>
    </dxi-item>
    <dxi-item [isRequired]="true">
      <dxo-label [text]="title"></dxo-label>
      <div *dxTemplate>
        <dx-text-area
          width="100%"
          [height]="150"
          [(value)]="reasonText"
        ></dx-text-area>
      </div>
      <dxi-validation-rule
        type="required"
        message="{{ title }} is required"
      ></dxi-validation-rule>
      <dxi-validation-rule
        type="stringLength"
        message="{{ title }} must be less than 150 characters"
        [max]="150"
      ></dxi-validation-rule>
    </dxi-item>
  </dx-form>
</app-page-popup>

<app-page-popup
  *ngIf="!!dataSource"
  height="75%"
  maxWidth="75%"
  [title]="title"
  [visible]="true"
  [fullScreen]="fullScreen"
  [buttons]="['Close']"
  (onHidden)="onClose.emit()"
>
  <dx-data-grid
    appDataGridFeatures
    width="100%"
    height="100%"
    keyExpr="propertyParticipantId"
    [enablePaging]="true"
    [dataSource]="dataSource"
  >
    <dxi-column
      dataField="isEligible"
      dataType="boolean"
      caption="Eligible"
      cellTemplate="checkCellTemplate"
    ></dxi-column>
    <dxi-column
      dataField="hasVotingRights"
      dataType="boolean"
      caption="Voting Rights"
      cellTemplate="checkCellTemplate"
    ></dxi-column>
    <dxi-column
      dataField="emailSent"
      dataType="boolean"
      caption="Sent"
      cellTemplate="checkCellTemplate"
      [visible]="isEmailStatus"
    ></dxi-column>
    <dxi-column
      dataField="emailSentDate"
      dataType="date"
      caption="Sent On"
      [format]="dateTimeFormat"
      [visible]="isEmailStatus"
    ></dxi-column>
    <dxi-column dataField="name" dataType="string"></dxi-column>
    <dxi-column dataField="email" dataType="string"></dxi-column>
    <dxi-column dataField="phone" dataType="string"></dxi-column>
    <dxi-column dataField="streetAddress" dataType="string"></dxi-column>
    <dxi-column dataField="phase" dataType="string"></dxi-column>
    <dxi-column dataField="section" dataType="string"></dxi-column>
    <dxi-column dataField="type" dataType="string"></dxi-column>
    <dxi-column dataField="mailingAddress" dataType="string"></dxi-column>
    <dxi-column dataField="mailingCSZ" dataType="string"></dxi-column>
    <div *dxTemplate="let cell of 'checkCellTemplate'">
      <app-check-cell-template
        [value]="cell.value"
        [tooltipText]="cell.data.emailError || cell.column.caption"
      ></app-check-cell-template>
    </div>
  </dx-data-grid>
</app-page-popup>

// Angular imports
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// 3rd party imports
import { plainToInstance } from 'class-transformer';

// Rxjs imports
import { map, Observable } from 'rxjs';

// Service imports
import { CachingService } from './caching.service';

// Constant imports
import { TimerConstants } from '../_constants/timer.constants';
import { UrlConstants } from '../_constants/url.constants';

// Model imports
import { KeyValueResultDto } from '../_models/common/key-value-result.dto';
import { BaseCommunityParameterDto } from '../_models/community/base-community-parameter.dto';
import { CommunityPropertyResultDto } from '../_models/property/community-property-result.dto';

@Injectable({ providedIn: 'root' })
export class PropertyService {
  constructor(
    protected readonly httpClient: HttpClient,
    protected readonly cachingService: CachingService
  ) {}

  getPropertyTypes(
    params: BaseCommunityParameterDto
  ): Observable<Array<KeyValueResultDto<number>>> {
    const url = `${UrlConstants.COMMUNITY_BASE_URL}/property/types`;
    const options = this.cachingService.register(
      url,
      TimerConstants.inHours(10),
      params
    );
    return this.httpClient
      .get<Array<any>>(url, options)
      .pipe(
        map((plain: Array<any>) =>
          plainToInstance(KeyValueResultDto<number>, plain)
        )
      );
  }

  getProperties(
    params: BaseCommunityParameterDto
  ): Observable<Array<CommunityPropertyResultDto>> {
    const url = `${UrlConstants.COMMUNITY_BASE_URL}/properties`;
    return this.httpClient
      .get<Array<any>>(url, { params: params.toQueryParams() })
      .pipe(
        map((plain: Array<any>) =>
          plainToInstance(CommunityPropertyResultDto, plain)
        )
      );
  }
}

// Angular imports
import { Component, EventEmitter, Input, Output } from '@angular/core';

// Model imports
import { KeyValueResultDto } from '../../../_models/common/key-value-result.dto';

@Component({
  selector: 'app-select-cell-template',
  templateUrl: './select-cell-template.component.html',
  styleUrls: ['./select-cell-template.component.scss']
})
export class SelectCellTemplateComponent {
  @Input() dataSource: Array<KeyValueResultDto<any> | any>;
  @Input() displayExpr = 'displayText';
  @Input() valueExpr = 'key';
  @Input() tooltipText: string;
  @Input() value: any;
  @Output() valueChange = new EventEmitter<any>();
  @Output() onValueChanged = new EventEmitter<any>();
}

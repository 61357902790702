<div
  *ngIf="!!targetUrl"
  class="cv-anchor-cell-template"
  [title]="tooltipText || displayText || targetUrl"
>
  <a
    class="cv-hyperlink cv-hyperlink-hover dx-theme-accent-as-text-color"
    target="_blank"
    [href]="targetUrl"
    (click)="onClick.emit()"
  >
    {{ displayText || targetUrl }}
  </a>
</div>

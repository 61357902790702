<div *ngIf="!!communications" class="cv-fullsize-content">
  <dx-data-grid
    #dataGrid
    appDataGridFeatures
    width="100%"
    height="100%"
    keyExpr="recordId"
    [gridFeatures]="{
      headerFilter: { visible: false },
      filterRow: { visible: false }
    }"
    [dataSource]="communications"
  >
    <dxo-toolbar>
      <dxi-item location="before">
        <div *dxTemplate>
          <dx-button
            type="default"
            stylingMode="outlined"
            [text]="isExpanded ? 'Collapse All' : 'Expand All'"
            [hint]="isExpanded ? 'Collapse All' : 'Expand All'"
            [icon]="iconToggleExpand"
            [visible]="communications?.length > 0"
            (onClick)="onToggleExpandAll(true)"
          ></dx-button>
        </div>
      </dxi-item>
      <dxi-item location="after" [visible]="!isReadOnly">
        <div *dxTemplate>
          <dx-button appButtonRefresh (onClick)="onRefreshClick()"></dx-button>
        </div>
      </dxi-item>
      <dxi-item location="after" [visible]="!isReadOnly">
        <div *dxTemplate>
          <dx-button
            type="default"
            text="New Schedule"
            hint="Add New Schedule"
            [icon]="iconPlus"
            [visible]="editorResult.allowCreate"
            (onClick)="showEditor = true"
          ></dx-button>
        </div>
      </dxi-item>
    </dxo-toolbar>
    <dxi-column
      type="actions"
      caption="Actions"
      cellTemplate="actionsCellTemplate"
      [visible]="!isReadOnly"
    >
      <div
        *dxTemplate="let cell of 'actionsCellTemplate'"
        class="cv-actions-cell-template"
      >
        <app-actions-cell-template
          [record]="cell.data"
          [rowIndex]="cell.rowIndex"
          [actionItems]="actionItems"
          [isActionVisible]="isActionVisible"
          (onActionClick)="onActionClick($event)"
        ></app-actions-cell-template>
      </div>
    </dxi-column>
    <dxi-column
      dataField="categoryName"
      dataType="string"
      caption="Category"
    ></dxi-column>
    <dxi-column
      dataField="templateName"
      dataType="string"
      caption="Template"
    ></dxi-column>
    <dxi-column dataField="recipientGroup" dataType="string"></dxi-column>
    <dxi-column dataField="referenceDateType" dataType="string"></dxi-column>
    <dxo-master-detail
      [enabled]="true"
      [autoExpandAll]="isExpanded"
      template="periodicity-details"
    ></dxo-master-detail>
    <div *dxTemplate="let row of 'periodicity-details'">
      <dx-data-grid
        [showBorders]="true"
        [columnAutoWidth]="true"
        [dataSource]="row.data.periodicities"
      >
        <dxi-column
          dataField="periodText"
          dataType="string"
          caption="Period"
        ></dxi-column>
        <dxi-column
          dataField="referenceDate"
          dataType="date"
          [format]="dateTimeFormat"
        ></dxi-column>
        <dxi-column
          dataField="scheduledDate"
          dataType="date"
          [format]="dateTimeFormat"
        ></dxi-column>
        <dxi-column
          dataField="recipientText"
          dataType="string"
          caption="Recipients"
          cellTemplate="linkCellTemplate"
        ></dxi-column>
        <dxi-column
          dataField="jobStatus"
          dataType="string"
          caption="Email Status"
          cellTemplate="statusCellTemplate"
        ></dxi-column>
        <div *dxTemplate="let cell of 'linkCellTemplate'">
          <app-link-cell-template
            [displayText]="cell.value"
            (onClick)="onLinkCellClick(row.data.recordId, cell.data)"
          ></app-link-cell-template>
        </div>
        <div *dxTemplate="let cell of 'statusCellTemplate'">
          <app-status-cell-template
            [status]="cell.value"
          ></app-status-cell-template>
        </div>
      </dx-data-grid>
    </div>
  </dx-data-grid>
</div>

<app-event-scheduled-communication-editor
  *ngIf="showEditor"
  [isReadOnly]="isReadOnly"
  [eventInfo]="eventInfo"
  [apiService]="apiService"
  [recipientGroups]="recipientGroups"
  [referenceDateTypes]="referenceDateTypes"
  [selectedRecord]="selectedRecord"
  (onSave)="onEditorSave()"
  (onCancel)="onEditorCancel()"
></app-event-scheduled-communication-editor>

<app-event-notices-status
  *ngIf="showNoticeStatus"
  title="Email Sent Status"
  [dataSource]="noticeStatus"
  (onClose)="showNoticeStatus = false"
></app-event-notices-status>

// Angular imports
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

// Dx imports
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxTextAreaComponent } from 'devextreme-angular/ui/text-area';

// Util imports
import { DxWidgetUtils } from '../../../_utils/dx-widget.utils';
import { Utils } from '../../../_utils/utils';

// Component imports
import { BaseComponent } from '../../../_base/base.component';

// Model imports
import { EventNotesResultDto } from '../../../_models/event/event-notes-result.dto';

@Component({
  selector: 'app-event-notes-manager',
  templateUrl: './event-notes-manager.component.html',
  styleUrls: ['./event-notes-manager.component.scss']
})
export class EventNotesManagerComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild(DxDataGridComponent, { static: false }) grid: DxDataGridComponent;
  @ViewChild(DxTextAreaComponent, { static: false }) text: DxTextAreaComponent;
  @Input() title: string;
  @Input() direction: 'vertical' | 'horizontal' = 'vertical';
  @Input() reversed = false;
  @Input() sizes: Array<number> = [70, 30];
  @Input() eventNotes: Array<EventNotesResultDto>;
  @Input() value = '';
  @Output() valueChange = new EventEmitter<string>();
  @Output() onSave = new EventEmitter<string>();
  @Output() onCancel = new EventEmitter<void>();

  orders: Array<number> = [1, 2];

  constructor(protected override readonly injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    if (Utils.isTrue(this.reversed)) {
      this.sizes.reverse();
      this.orders.reverse();
    }
  }

  onSplitDragEnd(e: any): void {
    this.sizes = Utils.isTrue(this.reversed) ? e.sizes.reverse() : e.sizes;
    DxWidgetUtils.instantRepaint(this.grid);
    DxWidgetUtils.instantRepaint(this.text);
  }

  onCancelClick(): void {
    this.value = '';
    this.onCancel.emit();
  }

  onSaveClick(): void {
    if (Utils.notNullOrEmpty(this.value)) {
      this.onSave.emit(this.value);
    }
  }
}

import { KeyValueResultDto } from '../_models/common/key-value-result.dto';

export enum DataSyncSource {
  None = 0,
  CiranetDB = 1,
  File = 2
}

export enum DataSyncSourceLabel {
  None = `None`,
  CiranetDB = `CiraNet DB`,
  File = `Data File`
}

export class DataSyncSources {
  static toList(): Array<KeyValueResultDto<number>> {
    return [
      {
        key: DataSyncSource.None,
        displayText: DataSyncSourceLabel.None
      },
      {
        key: DataSyncSource.CiranetDB,
        displayText: DataSyncSourceLabel.CiranetDB
      },
      {
        key: DataSyncSource.File,
        displayText: DataSyncSourceLabel.File
      }
    ];
  }
}

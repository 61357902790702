import { Type } from 'class-transformer';

export class SyncStatusInfoResultDto {
  syncSourceId: number;

  hasLastSyncedError: boolean;

  currentSyncedStatus: string;
  lastSyncedStatus: string;

  @Type(() => Date)
  lastSyncedDate: Date;
}

<div
  class="cv-selection-card cv-center-content cv-round-border cc-theme-shadow-box dx-theme-border-color dx-theme-background-color"
>
  <div class="cv-card-title dx-theme-accent-as-text-color">{{ title }}</div>
  <div class="cv-card-subtitle">
    {{ subtitle }}
  </div>
  <div
    class="cv-card-image cv-center-content cv-circle-border dx-theme-border-color"
  >
    <img [src]="image" [alt]="title" />
  </div>
  <div class="cv-card-button">
    <dx-button
      type="success"
      text="Select"
      hint="Select"
      [icon]="iconSelect"
      (onClick)="onSelectClick.emit(id)"
    ></dx-button>
  </div>
</div>

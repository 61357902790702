<app-page-popup
  *ngIf="!!editorParam"
  maxWidth="75%"
  title="Schedule Communication"
  [visible]="true"
  [closeOnly]="isReadOnly"
  [fullScreen]="fullScreen"
  [buttons]="actionButtons"
  (onHidden)="onCancel.emit()"
  (onButtonClick)="onButtonClick($event)"
>
  <dx-form
    #editorForm
    width="100%"
    height="100%"
    [colCount]="2"
    [readOnly]="isReadOnly"
    [scrollingEnabled]="true"
  >
    <dxi-item [isRequired]="true">
      <dxo-label text="Recipients Group"></dxo-label>
      <div *dxTemplate>
        <dx-select-box
          displayExpr="displayText"
          valueExpr="key"
          [readOnly]="isReadOnly"
          [dataSource]="recipientGroups"
          [(value)]="editorParam.recipientGroupId"
        ></dx-select-box>
      </div>
    </dxi-item>
    <dxi-item [isRequired]="true">
      <dxo-label text="Date Reference Type"></dxo-label>
      <div *dxTemplate class="cv-side-by-side-items">
        <dx-select-box
          width="100%"
          displayExpr="displayText"
          valueExpr="key"
          [readOnly]="isReadOnly"
          [dataSource]="referenceDateTypes"
          [(value)]="editorParam.referenceDateTypeId"
          (onValueChanged)="onReferenceDateTypeChanged($event)"
        ></dx-select-box>
        <div class="hspacer"></div>
        <dx-date-box
          width="100%"
          appDateBoxDateTime
          type="datetime"
          [min]="currentDate"
          [max]="eventInfo?.resultPublishLocalDate"
          [readOnly]="isReadOnly"
          [visible]="editorParam.referenceDateTypeId === 4"
          [value]="editorParam.referenceCustomDate"
          (onValueChanged)="onReferenceDateChanged($event)"
        ></dx-date-box>
      </div>
    </dxi-item>
    <dxi-item
      itemType="group"
      caption="Schedule Periodicity"
      [colSpan]="2"
      [colCount]="2"
    >
      <ng-container
        *ngFor="
          let info of editorParam.periodicities;
          let isFirst = first;
          let idx = index
        "
      >
        <dxi-item
          [isRequired]="true"
          [helpText]="info.allowChange ? 'Max Allowed: ' + maxPeriodDays : ''"
        >
          <dxo-label
            text="No. of Days before Reference Date"
            location="left"
          ></dxo-label>
          <div *dxTemplate class="cv-side-by-side-items">
            <dx-number-box
              width="60%"
              placeholder="No. of Days"
              [min]="0"
              [max]="maxPeriodDays"
              [readOnly]="isReadOnly"
              [disabled]="!info.allowChange"
              [(value)]="info.period"
            ></dx-number-box>
            <div class="hspacer"></div>
            <dx-button
              width="20%"
              type="danger"
              stylingMode="outlined"
              hint="Delete Period"
              [icon]="iconTrash"
              [visible]="!isReadOnly"
              [disabled]="
                !info.allowChange || editorParam.periodicities.length === 1
              "
              (onClick)="onRemovePeriodClick(idx)"
            ></dx-button>
            <div class="hspacer"></div>
            <dx-button
              width="20%"
              type="default"
              stylingMode="outlined"
              hint="Add New Period"
              [icon]="iconPlus"
              [visible]="!isReadOnly && isFirst"
              (onClick)="onAddPeriodClick()"
            ></dx-button>
            <!--Dummary Div to Adjust Space, Hence Inline Styling-->
            <div *ngIf="!isReadOnly && !isFirst" style="width: 20%"></div>
            <div class="hspacer"></div>
          </div>
        </dxi-item>
        <dxi-item itemType="empty"></dxi-item>
      </ng-container>
    </dxi-item>
    <dxi-item [isRequired]="true">
      <dxo-label text="Communication Category"></dxo-label>
      <div *dxTemplate>
        <dx-select-box
          displayExpr="name"
          valueExpr="categoryId"
          [readOnly]="isReadOnly"
          [dataSource]="categories"
          [(value)]="editorParam.categoryId"
          (onValueChanged)="onCategoryChanged($event)"
        ></dx-select-box>
      </div>
    </dxi-item>
    <dxi-item [isRequired]="true">
      <dxo-label text="Communication Template"></dxo-label>
      <div *dxTemplate>
        <dx-select-box
          displayExpr="name"
          valueExpr="templateId"
          [readOnly]="isReadOnly"
          [disabled]="!selectedCategory"
          [dataSource]="selectedCategory?.templates"
          [(value)]="editorParam.templateId"
          (onValueChanged)="onTemplateChanged($event)"
        ></dx-select-box>
      </div>
    </dxi-item>
    <dxi-item [isRequired]="true" [colSpan]="2">
      <dxo-label text="Subject" location="left"></dxo-label>
      <div *dxTemplate>
        <dx-text-box
          [readOnly]="isReadOnly"
          [(value)]="editorParam.subject"
        ></dx-text-box>
      </div>
      <dxi-validation-rule
        type="required"
        message="Subject is required"
      ></dxi-validation-rule>
      <dxi-validation-rule
        type="stringLength"
        message="Subject must be less than 500 characters"
        [max]="500"
      ></dxi-validation-rule>
    </dxi-item>
    <dxi-item [colSpan]="2">
      <dxo-label [visible]="false"></dxo-label>
      <div *dxTemplate>
        <app-html-editor
          *ngIf="!!mergeFields"
          height="300px"
          [fields]="mergeFields"
          [readOnly]="isReadOnly"
          [(value)]="editorParam.htmlContent"
          (onFullScreenChanged)="fullScreen = $event"
        ></app-html-editor>
      </div>
    </dxi-item>
    <dxi-item itemType="empty"></dxi-item>
  </dx-form>
</app-page-popup>

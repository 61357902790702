import { CommunityPropertyParameterDto } from './community-property-parameter.dto';

export class CommunityPropertyResultDto {
  entityId: number;
  propertyId: number;
  cityId: number;
  stateId: number;
  countryId: number;
  areaInSquareFeet: number;
  propertyTypeId: number;

  streetAddress: string;
  unitNumber: string;
  floorNumber: string;
  phase: string;
  section: string;
  stateName: string;
  cityName: string;
  countryName: string;
  postalCode: string;
  propertyType: string;

  isCoowned: boolean;
  isDelinquent: boolean;
  hasVotingRights: boolean;

  get addressText(): string {
    return `${this.cityName}, ${this.stateName}, ${this.countryName} - ${this.postalCode}`;
  }

  toParameter(): CommunityPropertyParameterDto {
    const params = new CommunityPropertyParameterDto();
    params.propertyId = this.propertyId;
    params.entityId = this.entityId;
    params.streetAddress = this.streetAddress;
    params.unitNumber = this.unitNumber;
    params.floorNumber = this.floorNumber;
    params.phase = this.phase;
    params.section = this.section;
    params.cityId = this.cityId;
    params.stateId = this.stateId;
    params.city = this.cityName;
    params.postalCode = this.postalCode;
    params.areaInSquareFeet = this.areaInSquareFeet;
    params.propertyTypeId = this.propertyTypeId;
    params.isCoowned = this.isCoowned;
    params.isDelinquent = this.isDelinquent;
    params.hasVotingRights = this.hasVotingRights;

    return params;
  }
}

import { Type } from 'class-transformer';
import { BaseEventParameterDto } from '../../../_models/event/base-event-parameter.dto';
import { EventScheduledCommunicationPeriodicityParameterDto } from './event-scheduled-communication-periodicity-parameter.dto';

export class EventScheduledCommunicationParameterDto extends BaseEventParameterDto {
  recordId: string;
  subject: string;
  htmlContent: string;

  categoryId: number;
  templateId: number;
  recipientGroupId: number;
  referenceDateTypeId: number;

  @Type(() => Date)
  referenceCustomDate: Date;

  @Type(() => EventScheduledCommunicationPeriodicityParameterDto)
  periodicities: Array<EventScheduledCommunicationPeriodicityParameterDto>;
}

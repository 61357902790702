// Angular imports
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

// Rxjs imports
import { Observable, forkJoin } from 'rxjs';

// Dx imports
import { DxFormComponent } from 'devextreme-angular/ui/form';

// 3rd party imports
import { plainToInstance } from 'class-transformer';

// Component imports
import { BaseComponent } from '../../../_base/base.component';

// Utils imports
import { DateUtils } from '../../../_utils/date.utils';
import { DxWidgetUtils } from '../../../_utils/dx-widget.utils';
import { Utils } from '../../../_utils/utils';

// Constant imports
import { ActionConstants } from '../../../_constants/action.constants';
import { IconConstants } from '../../../_constants/icon.constants';
import { TimezoneConstants } from '../../../_constants/timzones.contants';

// Enum imports
import { RecipientGroupType } from '../../../_enums/recipient-group-type.enum';
import { ReferenceDateType } from '../../../_enums/reference-date-type.enum';

// Type imports
import { EventContextInfo } from '../../../_types/event-context-info';
import { ScheduledCommunicationsService } from '../../../_types/scheduled-communications-service';

// Model imports
import { KeyValueResultDto } from '../../../_models/common/key-value-result.dto';
import { MergeFieldResultDto } from '../../../_models/common/merge-field-result.dto';
import { BaseEventNoticeParameterDto } from '../../../_models/event/base-event-notice-parameter.dto';
import { BaseEventParameterDto } from '../../../_models/event/base-event-parameter.dto';
import { EventCategoryResultDto } from '../../../_models/event/event-category-result.dto';
import { EventTemplateContentParameterDto } from '../../../_models/event/event-template-content-parameter.dto';
import { EventTemplateContentResultDto } from '../../../_models/event/event-template-content-result.dto';
import { EventScheduledCommunicationParameterDto } from '../../../_models/event/scheduled-communications/event-scheduled-communication-parameter.dto';
import { EventScheduledCommunicationPeriodicityParameterDto } from '../../../_models/event/scheduled-communications/event-scheduled-communication-periodicity-parameter.dto';
import { EventScheduledCommunicationResultDto } from '../../../_models/event/scheduled-communications/event-scheduled-communication-result.dto';
import { EventScheduledCommunicationSummaryResultDto } from '../../../_models/event/scheduled-communications/event-scheduled-communication-summary-result.dto';

@Component({
  selector: 'app-event-scheduled-communication-editor',
  templateUrl: './event-scheduled-communication-editor.component.html',
  styleUrls: ['./event-scheduled-communication-editor.component.scss']
})
export class EventScheduledCommunicationEditorComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild('editorForm', { static: false }) editorForm: DxFormComponent;

  @Input() isReadOnly: boolean;
  @Input() eventInfo: EventContextInfo;
  @Input() apiService: ScheduledCommunicationsService;
  @Input() recipientGroups: Array<KeyValueResultDto<number>>;
  @Input() referenceDateTypes: Array<KeyValueResultDto<number>>;
  @Input() selectedRecord: EventScheduledCommunicationSummaryResultDto;

  @Output() onSave = new EventEmitter<void>();
  @Output() onCancel = new EventEmitter<void>();

  iconPlus = IconConstants.PLUS;
  iconTrash = IconConstants.TRASH;

  fullScreen = false;
  isEditMode = false;

  maxPeriodDays = 60;

  actionButtons = ['Cancel', 'Save'];
  mergeFields: Array<MergeFieldResultDto>;
  categories: Array<EventCategoryResultDto>;
  selectedCategory: EventCategoryResultDto;

  editorParam: EventScheduledCommunicationParameterDto;

  constructor(protected override readonly injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.organizationInfo = this.contextService.getOrganization();
    this.isEditMode = Utils.notNullAndDefined(this.selectedRecord);
    forkJoin([this.populateTemplates(), this.populateMergeFields()]).subscribe(
      () => {
        if (Utils.notNullAndDefined(this.selectedRecord)) {
          this.populateScheduledCommunication();
        } else {
          const editorParam = this.getUpdatedParameter(
            new EventScheduledCommunicationParameterDto()
          );
          editorParam.recipientGroupId = RecipientGroupType.AllParticipants;
          editorParam.referenceDateTypeId = ReferenceDateType.EventCloseDate;
          this.updateMaxPeriodDays(ReferenceDateType.EventCloseDate);
          editorParam.periodicities = plainToInstance(
            EventScheduledCommunicationPeriodicityParameterDto,
            [14, 45, 60]
              .filter((p) => p <= this.maxPeriodDays)
              .map((p) => {
                return {
                  periodId: Utils.getUUID(),
                  period: p,
                  allowChange: true
                };
              })
          );
          if (Utils.isNullOrEmpty(editorParam.periodicities)) {
            editorParam.periodicities = [
              plainToInstance(
                EventScheduledCommunicationPeriodicityParameterDto,
                {
                  periodId: Utils.getUUID(),
                  period: this.maxPeriodDays,
                  allowChange: true
                }
              )
            ];
          }
          this.editorParam = editorParam;
        }
      }
    );
  }

  onCategoryChanged(e: any): void {
    if (
      Utils.notNullAndDefined(this.selectedCategory) &&
      Utils.notEquals(e.value, this.selectedCategory.categoryId)
    ) {
      this.editorParam.templateId = null;
      this.editorParam.subject = Utils.emptyText();
      this.editorParam.htmlContent = Utils.emptyText();
    }
    this.selectedCategory = this.categories.find((r) =>
      Utils.equals(r.categoryId, e.value)
    );
    this.updateTemplateContent();
  }

  onTemplateChanged(e: any): void {
    if (Utils.notNullAndDefined(e.value)) {
      this.populateTemplateContent();
    }
  }

  onReferenceDateTypeChanged(e: any): void {
    if (Utils.notEquals(e.value, ReferenceDateType.CustomDate)) {
      this.editorParam.referenceCustomDate = null;
    }
    this.updateMaxPeriodDays(e.value, this.editorParam.referenceCustomDate);
  }

  onReferenceDateChanged(e: any): void {
    this.editorParam.referenceCustomDate = e.value;
    this.updateMaxPeriodDays(this.editorParam.referenceDateTypeId, e.value);
  }

  onAddPeriodClick(): void {
    const newEntry = plainToInstance(
      EventScheduledCommunicationPeriodicityParameterDto,
      {
        periodId: Utils.getUUID(),
        period: null,
        allowChange: true
      }
    );
    this.editorParam.periodicities.push(newEntry);
  }

  onRemovePeriodClick(index: number): void {
    this.editorParam.periodicities.splice(index, 1);
  }

  onButtonClick(name: string): void {
    if (
      Utils.equalsIgnoreCase(name, ActionConstants.SAVE) &&
      DxWidgetUtils.isFormValid(this.editorForm) &&
      this.validatePeriodicities()
    ) {
      if (Utils.isNullOrUndefined(this.editorParam.recordId)) {
        this.saveScheduledCommunication();
      } else {
        this.updateScheduledCommunication();
      }
    }
  }

  private validatePeriodicities(): boolean {
    // Validate for max allowe period days
    const invalidRecords = this.editorParam.periodicities
      .filter((r) => r.allowChange && r.period > this.maxPeriodDays)
      .map((r) => `Invalid period value ${r.period}`);
    if (Utils.notNullOrEmpty(invalidRecords)) {
      this.showError(invalidRecords.join(', '));
      return false;
    }

    // Validate for duplicates
    const duplicateRecords = this.editorParam.periodicities
      .filter((r) => r.allowChange)
      .filter((r, i, a) => Utils.hasDuplicates(r.period, a, 'period'))
      .map((r) => `Duplicate period value ${r.period}`);
    if (Utils.notNullOrEmpty(duplicateRecords)) {
      this.showError(duplicateRecords.join(', '));
      return false;
    }

    return true;
  }

  private updateMaxPeriodDays(
    referenceDateTypeId: number,
    referenceCustomDate?: Date
  ): void {
    const tzCode = TimezoneConstants.tzCode(this.eventInfo.eventTimeZone);
    this.currentDate = DateUtils.toLocalDateTime(new Date(), tzCode);

    let referenceDate = referenceCustomDate;
    if (Utils.equals(referenceDateTypeId, ReferenceDateType.EventCloseDate)) {
      referenceDate = this.eventInfo.resultPublishLocalDate;
    } else if (
      Utils.equals(referenceDateTypeId, ReferenceDateType.ParticipationEndDate)
    ) {
      referenceDate = this.eventInfo.participationEndLocalDate;
    } else if (
      Utils.equals(
        referenceDateTypeId,
        ReferenceDateType.ParticipationStartDate
      )
    ) {
      referenceDate = this.eventInfo.participationStartLocalDate;
    }
    if (Utils.notNullAndDefined(referenceDate)) {
      const maxPeriodDays = DateUtils.differenceInDays(
        this.currentDate,
        referenceDate
      );
      this.maxPeriodDays = Math.max(0, maxPeriodDays);
    }
  }

  private updateTemplateContent(): void {
    if (
      Utils.isFalse(this.isEditMode) &&
      Utils.notNullAndDefined(this.selectedCategory) &&
      Utils.notNullOrEmpty(this.selectedCategory.templates)
    ) {
      let template = null;
      if (Utils.equals(this.selectedCategory.templates.length, 1)) {
        template = this.selectedCategory.templates.at(0);
        this.editorParam.templateId = template.templateId;
        this.populateTemplateContent();
      } else if (Utils.notNullAndDefined(this.editorParam.templateId)) {
        template = this.selectedCategory.templates.find((t) =>
          Utils.equals(t.templateId, this.editorParam.templateId)
        );
      }

      if (Utils.isNullOrUndefined(template)) {
        this.editorParam.templateId = null;
        this.editorParam.subject = Utils.emptyText();
        this.editorParam.htmlContent = Utils.emptyText();
      }
    }
  }

  private populateMergeFields(): Observable<void> {
    return new Observable((observer) => {
      const params = this.getUpdatedParameter(
        new BaseEventNoticeParameterDto()
      );
      this.apiService
        .getScheduledCommunicationTemplateMergeFields(params)
        .subscribe((results: Array<MergeFieldResultDto>) => {
          this.mergeFields = results;
          observer.next();
          observer.complete();
        });
    });
  }

  private populateTemplates(): Observable<void> {
    return new Observable((observer) => {
      const params = this.getUpdatedParameter(
        new BaseEventNoticeParameterDto()
      );
      this.apiService
        .getScheduledCommunicationTemplates(params)
        .subscribe((results: Array<EventCategoryResultDto>) => {
          this.categories = results;
          observer.next();
          observer.complete();
        });
    });
  }

  private populateScheduledCommunication(): void {
    const params = this.getUpdatedParameter(new BaseEventNoticeParameterDto());
    params.recordId = this.selectedRecord.recordId;
    this.apiService
      .getScheduledCommunication(params)
      .subscribe((result: EventScheduledCommunicationResultDto) => {
        this.editorParam = this.getUpdatedParameter(
          result.toCommunicationParameter()
        );
        this.onCategoryChanged({ value: result.categoryId });
        this.onReferenceDateTypeChanged({ value: result.referenceDateTypeId });
      });
  }

  private populateTemplateContent(): void {
    const params = this.getUpdatedParameter(
      new EventTemplateContentParameterDto()
    );
    params.categoryId = this.editorParam.categoryId;
    params.templateId = this.editorParam.templateId;
    this.apiService
      .getScheduledCommunicationTemplateContent(params)
      .subscribe((result: EventTemplateContentResultDto) => {
        this.editorParam.htmlContent = result.htmlBody
          .replace(/\\r\\b/gi, '')
          .replace(/\\"/gi, '"')
          .replace(/&quot;/gi, '');
        this.editorParam.subject = result.subject;
      });
  }

  private saveScheduledCommunication(): void {
    this.apiService
      .saveScheduledCommunication(this.editorParam)
      .subscribe(() => {
        const message = `Scheduled Communication Information Saved Successfully`;
        this.showSuccess(message);
        this.onSave.emit();
      });
  }

  private updateScheduledCommunication(): void {
    this.apiService
      .updateScheduledCommunication(this.editorParam)
      .subscribe(() => {
        const message = `Scheduled Communication Information Updated Successfully`;
        this.showSuccess(message);
        this.onSave.emit();
      });
  }

  private getUpdatedParameter<T extends BaseEventParameterDto>(params: T): T {
    params.organizationId = this.organizationInfo.organizationId;
    params.entityId = this.eventInfo.entityId;
    params.eventId = this.eventInfo.eventId;
    return params;
  }
}

// Angular imports
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

// Dx imports
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { DxTagBoxComponent } from 'devextreme-angular/ui/tag-box';

// Util imports
import { DxWidgetUtils } from '../../../_utils/dx-widget.utils';
import { Utils } from '../../../_utils/utils';

// Component imports
import { BaseComponent } from '../../../_base/base.component';

// Constant imports
import { ActionConstants } from '../../../_constants/action.constants';
import { IconConstants } from '../../../_constants/icon.constants';

// Model imports
import { MergeFieldResultDto } from '../../../_models/common/merge-field-result.dto';
import { EventParticipantResultDto } from '../../../_models/event/event-participant-result.dto';
import { EventTemplateContentResultDto } from '../../../_models/event/event-template-content-result.dto';
import { EventTemplatePreviewParameterDto } from '../../../_models/event/event-template-preview-parameter.dto';

@Component({
  selector: 'app-event-mailer-preview',
  templateUrl: './event-mailer-preview.component.html',
  styleUrls: ['./event-mailer-preview.component.scss']
})
export class EventMailerPreviewComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @ViewChild('editorForm', { static: false }) editorForm: DxFormComponent;
  @ViewChild('recipientsBox', { static: false })
  recipientsBox: DxTagBoxComponent;

  @Input() title = `Mailer Preview`;
  @Input() mailerInfo: EventTemplateContentResultDto;
  @Input() mergeFields: Array<MergeFieldResultDto> = [];
  @Input() recipients: Array<EventParticipantResultDto> = [];
  @Input() selectedIds: Array<number>;
  @Input() allowEmailEntry = false;
  @Input() saveAsSend = false;
  @Input() readOnly = false;
  @Output() selectedIdsChange = new EventEmitter<Array<number>>();
  @Output() onSave = new EventEmitter<EventTemplatePreviewParameterDto>();
  @Output() onEmailEntry = new EventEmitter<Array<EventParticipantResultDto>>();
  @Output() onCancel = new EventEmitter<void>();
  @Output() onSearch = new EventEmitter<void>();

  iconSearch = IconConstants.SEARCH;
  fullScreen = false;
  buttonOptions = ['Cancel', 'Save'];
  editorParam: EventTemplatePreviewParameterDto;
  customEmails: Array<EventParticipantResultDto> = [];

  constructor(protected override readonly injector: Injector) {
    super(injector);
    this.onSearchClick = this.onSearchClick.bind(this);
  }

  ngOnInit(): void {
    if (Utils.isTrue(this.saveAsSend)) {
      this.buttonOptions = ['Cancel', 'Send'];
    }
    if (Utils.isTrue(this.readOnly)) {
      this.buttonOptions = ['Close'];
    }
    if (Utils.notNullAndDefined(this.mailerInfo)) {
      this.editorParam = new EventTemplatePreviewParameterDto();
      this.editorParam.subject = this.mailerInfo.subject;
      this.editorParam.htmlContent = this.mailerInfo.htmlBody;
      this.editorParam.recipientIds = this.selectedIds;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      Utils.notNullAndDefined(changes['recipients']) &&
      Utils.notNullOrEmpty(this.recipients)
    )
      this.customEmails = this.recipients.filter(
        (r) => r.propertyParticipantId <= 0
      );
  }

  onSearchClick(): void {
    this.onSearch.emit();
  }

  onButtonClick(name: string): void {
    if (
      (Utils.equalsIgnoreCase(name, ActionConstants.SAVE) ||
        Utils.equalsIgnoreCase(name, ActionConstants.SEND)) &&
      DxWidgetUtils.isFormValid(this.editorForm)
    ) {
      if (
        Utils.equalsIgnoreCase(name, ActionConstants.SEND) &&
        Utils.isNullOrEmpty(this.selectedIds)
      ) {
        this.recipientsBox.instance.option({
          validationStatus: 'invalid',
          validationError: {
            type: 'required',
            message: 'Recipients is required'
          }
        });
      } else {
        this.editorParam.recipientEmails = this.customEmails
          .filter(
            (ce) =>
              Utils.isTrue(ce.isSelected) ||
              Utils.isInList(ce.propertyParticipantId, this.selectedIds)
          )
          .map((ce) => ce.email);
        this.editorParam.recipientIds = this.selectedIds.filter((id) => id > 0);
        this.onSave.emit(this.editorParam);
      }
    }
  }

  onCustomItemCreating(e: any): void {
    if (Utils.notNullOrEmpty(e.text)) {
      const typedText = e.text.trim();
      let customItem = this.customEmails.find((r) =>
        Utils.equalsIgnoreCase(r.email, typedText)
      );
      if (Utils.isNullOrUndefined(customItem)) {
        customItem = new EventParticipantResultDto();
        customItem.propertyParticipantId = this.recipients.length * -999;
        customItem.email = typedText;
        customItem.isSelected = true;
        this.customEmails.push(customItem);
        this.recipients.push(customItem);
        this.onEmailEntry.emit(this.customEmails);
      }
      e.customItem = customItem;
    } else {
      e.customItem = null;
    }
  }

  onRecipientsChanged(e: any): void {
    this.recipientsBox.instance.resetOption('validationStatus');
    this.selectedIdsChange.emit(e.value);
    this.customEmails.forEach(
      (ce) =>
        (ce.isSelected = Utils.isInList(
          ce.propertyParticipantId,
          this.selectedIds
        ))
    );
  }
}

// Angular imports
import { Pipe, PipeTransform } from '@angular/core';

// Util imports
import { FileUtils } from '../_utils/file.utils';

@Pipe({ name: 'fileIcon' })
export class FileIconPipe implements PipeTransform {
  transform(fileName: string): string {
    return FileUtils.getFileIcon(fileName);
  }
}

<app-page-popup
  *ngIf="visible"
  title="Choose City"
  [width]="800"
  [height]="600"
  [visible]="visible"
  [buttons]="['Close']"
  (onHidden)="onHidden()"
>
  <dx-data-grid
    appDataGridFeatures
    width="100%"
    height="100%"
    keyExpr="cityId"
    [dataSource]="cities"
    [(selectedRowKeys)]="selectedKeys"
  >
    <dxo-toolbar>
      <dxi-item location="before">
        <div *dxTemplate>
          <dx-text-box
            placeholder="Enter Search Term"
            [width]="450"
            [buttons]="[
              {
                name: 'search',
                location: 'after',
                options: {
                  type: 'default',
                  stylingMode: 'text',
                  hint: 'Search Cities',
                  icon: iconSearch,
                  useSubmitBehavior: true,
                  onClick: onSearchClick
                }
              },
              {
                name: 'creator',
                location: 'after',
                options: {
                  type: 'default',
                  stylingMode: 'text',
                  hint: 'Add New City',
                  icon: iconAdd,
                  onClick: onAddCityClick
                }
              }
            ]"
            [(value)]="searchParam.searchTerm"
            (onEnterKey)="onSearchClick()"
          >
            <dx-validator>
              <dxi-validation-rule
                type="required"
                message="Search term is required"
              ></dxi-validation-rule>
              <dxi-validation-rule
                [min]="3"
                type="stringLength"
                message="At least 3 characters is must in search term"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
      </dxi-item>
    </dxo-toolbar>
    <dxo-selection mode="single"></dxo-selection>
    <dxi-column type="actions" cellTemplate="buttonCellTemplate"></dxi-column>
    <dxi-column dataField="cityName" dataType="string"></dxi-column>
    <dxi-column dataField="stateName" dataType="string"></dxi-column>
    <dxi-column dataField="countryName" dataType="string"></dxi-column>
    <div *dxTemplate="let cell of 'buttonCellTemplate'">
      <app-button-cell-template
        (onClick)="onSelectClick(cell.data)"
      ></app-button-cell-template>
    </div>
  </dx-data-grid>
</app-page-popup>

<app-country-state-selector
  *ngIf="showAddCity"
  [visible]="showAddCity"
  [cityName]="searchParam.searchTerm"
  (onOk)="onAddCityOk($event)"
  (onCancel)="showAddCity = false"
></app-country-state-selector>

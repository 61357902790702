export enum JobStatusType {
  NotScheduled = 0,
  Scheduled = 1,
  InProgress = 2,
  Partial = 3,
  Completed = 4,
  Errored = 5
}

export enum JobStatusTypeLabel {
  NotScheduled = `Not Scheduled`,
  Scheduled = `Scheduled`,
  InProgress = `In Progress`,
  Partial = `Partial`,
  Completed = `Completed`,
  Errored = `Errored`
}

// Angular imports
import { Component, EventEmitter, Input, Output } from '@angular/core';

// Constant imports
import { IconConstants } from '../../../_constants/icon.constants';

@Component({
  selector: 'app-selection-card',
  templateUrl: './selection-card.component.html',
  styleUrls: ['./selection-card.component.scss']
})
export class SelectionCardComponent {
  @Input() id: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() image: string;
  @Output() onSelectClick = new EventEmitter<string>();

  iconSelect = IconConstants.SELECT;
}

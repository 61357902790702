// Angular imports
import { Pipe, PipeTransform } from '@angular/core';

// Util imports
import { Utils } from '../_utils/utils';

// Constant imports
import { ActionConstants } from '../_constants/action.constants';

@Pipe({ name: 'filterDeleted' })
export class FilterDeletedPipe implements PipeTransform {
  transform(records: Array<any>): Array<any> {
    if (Utils.notNullOrEmpty(records)) {
      return records.filter((r) =>
        Utils.notEqualsIgnoreCase(r.crudAction, ActionConstants.DELETE)
      );
    }
    return records;
  }
}

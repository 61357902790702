import { Transform } from 'class-transformer';
import { GeoLocationUtils } from '../../_utils/geo-location.utils';
import { BaseCommunityPropertyParameterDto } from './base-community-property-parameter.dto';

export class CommunityPropertyParameterDto extends BaseCommunityPropertyParameterDto {
  @Transform(GeoLocationUtils.toJsonCityId, { toPlainOnly: true })
  cityId: number;
  stateId: number;
  areaInSquareFeet: number;
  propertyTypeId: number;

  streetAddress: string;
  unitNumber: string;
  floorNumber: string;
  phase: string;
  section: string;
  city: string;
  postalCode: string;

  isCoowned: boolean;
  isDelinquent: boolean;
  hasVotingRights: boolean;
}

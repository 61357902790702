// Angular imports
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  Output
} from '@angular/core';

// Component imports
import { BaseComponent } from '../../../_base/base.component';

// Model imports
import { EventEmailRecipientResultDto } from '../../../_models/event/event-email-recipient-result.dto';
import { EventPaperCommunicationStatusResultDto } from '../../../_models/event/event-paper-communication-status-result.dto';

@Component({
  selector: 'app-event-notices-status',
  templateUrl: './event-notices-status.component.html',
  styleUrls: ['./event-notices-status.component.scss']
})
export class EventNoticesStatusComponent extends BaseComponent {
  @Input() title = `Event Notices Status`;
  @Input() fullScreen = false;
  @Input() isEmailStatus = true;
  @Input() dataSource: Array<
    EventEmailRecipientResultDto | EventPaperCommunicationStatusResultDto
  >;
  @Output() onClose = new EventEmitter<void>();

  constructor(protected override readonly injector: Injector) {
    super(injector);
  }
}

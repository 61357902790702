import { Type } from 'class-transformer';
import { DataSyncSource } from '../../_enums/data-sync-source-enum';
import { JobStatusTypeLabel } from '../../_enums/job-status-type.enum';
import { Utils } from '../../_utils/utils';
import { LinkedEntityInfoResultDto } from '../common/linked-entity-info-result.dto';
import { SyncStatusInfoResultDto } from '../common/sync-status-info-result.dto';
import { UsernameDisplayInfoResultDto } from '../common/username-display-info-result.dto';
import { CommunityEntityParameterDto } from './community-entity-parameter.dto';

export class CommunitySelectorResultDto {
  id: number;
  cityId: number;
  stateId: number;
  countryId: number;
  organizationId: number;
  communityTypeId: number;
  residentialUnitCount: number;
  commercialUnitCount: number;

  cityName: string;
  stateName: string;
  countryName: string;
  postalCode: string;
  legalName: string;
  referredName: string;
  communityType: string;
  address1: string;
  address2: string;

  isNonprofit: boolean;

  @Type(() => UsernameDisplayInfoResultDto)
  manager: UsernameDisplayInfoResultDto;

  @Type(() => LinkedEntityInfoResultDto)
  linkedEntityInfo: LinkedEntityInfoResultDto;

  @Type(() => SyncStatusInfoResultDto)
  syncStatusInfo: SyncStatusInfoResultDto;

  get name(): string {
    return this.referredName || this.legalName;
  }

  get noOfUnitsText(): string {
    return String(this.residentialUnitCount || '');
  }

  get contactText(): string {
    return Utils.notNullAndDefined(this.manager)
      ? `${this.manager.name}`
      : null;
  }

  get addressText(): string {
    return `${this.cityName}, ${this.stateName}, ${this.countryName} - ${this.postalCode}`;
  }

  get provinceText(): string {
    return `${this.cityName}, ${this.stateName}`;
  }

  get linkedWithCiraNet(): boolean {
    return (
      Utils.notNullAndDefined(this.linkedEntityInfo) &&
      Utils.isFalse(this.syncFromFile)
    );
  }

  get syncSource(): number {
    return Utils.notNullAndDefined(this.syncStatusInfo)
      ? this.syncStatusInfo.syncSourceId
      : null;
  }

  get syncFromFile(): boolean {
    return (
      Utils.notNullAndDefined(this.syncStatusInfo) &&
      Utils.equals(this.syncStatusInfo.syncSourceId, DataSyncSource.File)
    );
  }

  get syncStatus(): string {
    return Utils.notNullAndDefined(this.syncStatusInfo)
      ? this.syncStatusInfo.currentSyncedStatus ||
          this.syncStatusInfo.lastSyncedStatus
      : null;
  }

  get syncDate(): Date {
    return Utils.notNullAndDefined(this.syncStatusInfo)
      ? this.syncStatusInfo.lastSyncedDate
      : null;
  }

  get hasLastSyncError(): boolean {
    return Utils.notNullAndDefined(this.syncStatusInfo)
      ? this.syncStatusInfo.hasLastSyncedError
      : null;
  }

  get allowSync(): boolean {
    return (
      Utils.isNullOrEmpty(this.syncStatus) ||
      Utils.notInList(this.syncStatus, [
        JobStatusTypeLabel.Scheduled,
        JobStatusTypeLabel.InProgress
      ])
    );
  }

  toParameter(): CommunityEntityParameterDto {
    const params = new CommunityEntityParameterDto();
    params.organizationId = this.organizationId;
    params.entityId = this.id;
    params.communityTypeId = this.communityTypeId;
    params.cityId = this.cityId;
    params.cityName = this.cityName;
    params.stateId = this.stateId;
    params.postalCode = this.postalCode;
    params.legalName = this.legalName;
    params.referredName = this.referredName;
    params.address1 = this.address1;
    params.address2 = this.address2;
    params.isNonprofit = this.isNonprofit;
    params.residentialUnitCount = this.residentialUnitCount;
    params.commercialUnitCount = this.commercialUnitCount;
    params.managerUserId = Utils.notNullAndDefined(this.manager)
      ? this.manager.userId
      : null;

    return params;
  }
}

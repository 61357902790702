// Angular imports
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-anchor-cell-template',
  templateUrl: './anchor-cell-template.component.html',
  styleUrls: ['./anchor-cell-template.component.scss']
})
export class AnchorCellTemplateComponent {
  @Input() targetUrl: string;
  @Input() displayText: string;
  @Input() tooltipText: string;
  @Output() onClick = new EventEmitter<void>();
}

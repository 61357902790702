import { Type } from 'class-transformer';
import { PropertyParticipantResultDto } from '../../admin-module/property-participant-module/_models/property-participant-result.dto';

export class EventParticipantResultDto extends PropertyParticipantResultDto {
  isEligible: boolean;
  isSelected: boolean;

  emailSent: boolean;

  @Type(() => Date)
  emailSentDate: Date;

  loginCount: number;
}

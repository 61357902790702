import { Utils } from '../../_utils/utils';

export class BaseParticipantResultDto {
  propertyParticipantId: number;
  propertyParticipantTypeId: number;
  participantId: number;
  propertyId: number;
  propertyTypeId: number;
  ownerNumber: number;
  areaInSquareFeet: number;
  cityId: number;
  stateId: number;
  weightage: number;

  customerId: string;
  email: string;
  secondaryEmail: string;
  fullName: string;
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  country: string;
  city: string;
  state: string;
  postalCode: string;
  streetAddress: string;
  unitNumber: string;
  floorNumber: string;
  phase: string;
  section: string;
  homePhone: string;
  secondaryPhone: string;
  cellPhone: string;
  propertyType: string;
  propertyParticipantType: string;

  isCoowned: boolean;
  isDelinquent: boolean;
  hasVotingRights: boolean;

  get name(): string {
    return `${this.firstName || ''} ${this.lastName || ''}`.trim();
  }

  get phone(): string {
    return this.cellPhone || this.homePhone || this.secondaryPhone;
  }

  get type(): string {
    return this.propertyParticipantType;
  }

  get propertyAddress(): string {
    return `${this.streetAddress}`;
  }

  get mailingAddress(): string {
    return `${this.address1 || ''} ${this.address2 || ''}`.trim();
  }

  get mailingCSZ(): string {
    return Utils.notNullOrEmpty(this.city)
      ? `${this.city}, ${this.state} ${this.postalCode}`
      : '';
  }
}

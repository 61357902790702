import { BaseParticipantResultDto } from '../../../_models/participant/base-participant-result.dto';
import { UpdatePropertyParticipantParameterDto } from './update-property-participant-parameter.dto';

export class PropertyParticipantResultDto extends BaseParticipantResultDto {
  participantPropertyStatus: string;
  participantPropertyReason: string;
  ineligibilityReason: string;

  toParameter(): UpdatePropertyParticipantParameterDto {
    const params = new UpdatePropertyParticipantParameterDto();
    params.email = this.email;
    params.firstName = this.firstName;
    params.lastName = this.lastName;
    params.address1 = this.address1;
    params.address2 = this.address2;
    params.cityId = this.cityId;
    params.stateId = this.stateId;
    params.cityName = this.city;
    params.postalCode = this.postalCode;
    params.homePhone = this.homePhone;
    params.cellPhone = this.cellPhone;
    params.secondaryEmail = this.secondaryEmail;
    params.secondaryPhone = this.secondaryPhone;
    params.propertyId = this.propertyId;
    params.propertyParticipantTypeId = this.propertyParticipantTypeId;
    params.customerId = this.customerId;
    params.ownerNumber = this.ownerNumber;
    params.propertyParticipantId = this.propertyParticipantId;
    params.participantId = this.participantId;
    params.weightage = this.weightage;

    return params;
  }
}

// Angular imports
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output
} from '@angular/core';

// Util imports
import { Utils } from '../../../_utils/utils';

// Component imports
import { BaseComponent } from '../../../_base/base.component';

// Constant imports
import { ActionConstants } from '../../../_constants/action.constants';
import { IconConstants } from '../../../_constants/icon.constants';

// Enum imports
import { JobStatusTypeLabel } from '../../../_enums/job-status-type.enum';

// Type imports
import {
  ActionMenuEvent,
  ActionMenuInfo
} from '../../../_types/action-menu-info';

// Service imports
import { PaperCommunicationService } from '../../../_types/paper-communication-service';

// Model imports
import { BaseAccessResultDto } from '../../../_models/common/base-access-result.dto';
import { BaseEventParameterDto } from '../../../_models/event/base-event-parameter.dto';
import { EventPaperCommunicationParameterDto } from '../../../_models/event/event-paper-communication-parameter.dto';
import { EventPaperCommunicationResultDto } from '../../../_models/event/event-paper-communication-result.dto';
import { EventPaperCommunicationStatusResultDto } from '../../../_models/event/event-paper-communication-status-result.dto';

@Component({
  selector: 'app-paper-communication-manager',
  templateUrl: './paper-communication-manager.component.html',
  styleUrls: ['./paper-communication-manager.component.scss']
})
export class PaperCommunicationManagerComponent
  extends BaseComponent
  implements OnInit
{
  @Input() isReadOnly: boolean;
  @Input() getUpdatedParamsFn: <T extends BaseEventParameterDto>(
    params: T
  ) => T;
  @Input() editorResult: BaseAccessResultDto;
  @Input() apiService: PaperCommunicationService;
  @Input() dataSource: Array<EventPaperCommunicationResultDto>;
  @Output() onRefresh = new EventEmitter<void>();

  showStatus = false;
  statusResults: Array<EventPaperCommunicationStatusResultDto>;
  actionItems: Array<ActionMenuInfo>;

  constructor(protected override readonly injector: Injector) {
    super(injector);
    this.isActionVisible = this.isActionVisible.bind(this);
  }

  ngOnInit(): void {
    this.actionItems = [
      {
        id: ActionConstants.REGENERATE,
        name: ActionConstants.REGENERATE,
        icon: IconConstants.INPROGRESS
      },
      {
        id: ActionConstants.DOWNLOAD,
        name: ActionConstants.DOWNLOAD,
        icon: IconConstants.DOWNLOAD
      },
      {
        id: ActionConstants.DELETE,
        name: ActionConstants.DELETE,
        icon: IconConstants.DELETE
      }
    ];
  }

  isActionVisible(
    e: ActionMenuEvent<EventPaperCommunicationResultDto>
  ): boolean {
    switch (e.action.id) {
      case ActionConstants.REGENERATE:
        return (
          Utils.isTrue(this.editorResult.allowUpdate) &&
          Utils.notNullOrEmpty(e.record.generatedFileDownloadUrl) &&
          Utils.notEqualsIgnoreCase(
            e.record.jobStatus,
            JobStatusTypeLabel.Scheduled
          )
        );
      case ActionConstants.DOWNLOAD:
        return (
          Utils.isTrue(this.editorResult.allowView) &&
          Utils.notNullOrEmpty(e.record.generatedFileDownloadUrl)
        );
      case ActionConstants.DELETE:
        return (
          Utils.isTrue(this.editorResult.allowDelete) &&
          Utils.notNullOrEmpty(e.record.generatedFileDownloadUrl) &&
          Utils.notEqualsIgnoreCase(
            e.record.jobStatus,
            JobStatusTypeLabel.Scheduled
          )
        );
      default:
        return false;
    }
  }

  onActionClick(e: ActionMenuEvent<EventPaperCommunicationResultDto>): void {
    if (Utils.equalsIgnoreCase(e.action.id, ActionConstants.REGENERATE)) {
      this.regenratePaperCommunication(
        e.record.communicationRecordId,
        e.record.recordId
      );
    } else if (Utils.equalsIgnoreCase(e.action.id, ActionConstants.DOWNLOAD)) {
      this.downloadPaperCommunication(
        e.record.communicationRecordId,
        e.record.recordId
      );
    } else if (Utils.equalsIgnoreCase(e.action.id, ActionConstants.DELETE)) {
      this.messageService
        .showConfirmMessage(
          'Confirmation',
          'Are you sure you want to delete this record?',
          'danger'
        )
        .then((choice: boolean) => {
          if (Utils.isTrue(choice)) {
            this.deletePaperCommunication(
              e.record.communicationRecordId,
              e.record.recordId
            );
          }
        });
    }
  }

  onLinkCellClick(record: EventPaperCommunicationResultDto): void {
    this.showStatus = false;
    const params = this.getUpdatedParamsFn(
      new EventPaperCommunicationParameterDto()
    );
    params.recordId = record.communicationRecordId;
    params.paperCommunicationRecordId = record.recordId;
    this.apiService
      .getPaperCommunicationStatus(params)
      .subscribe((results: Array<EventPaperCommunicationStatusResultDto>) => {
        this.statusResults = results;
        this.showStatus = true;
      });
  }

  private regenratePaperCommunication(
    recordId: string,
    paperCommunicationRecordId: string
  ): void {
    const params = this.getUpdatedParamsFn(
      new EventPaperCommunicationParameterDto()
    );
    params.recordId = recordId;
    params.paperCommunicationRecordId = paperCommunicationRecordId;
    this.apiService.regeneratePaperCommunication(params).subscribe(() => {
      this.showSuccess(
        'Paper Communication Generation Rescheduled Successfully.'
      );
      this.onRefresh.emit();
    });
  }

  private downloadPaperCommunication(
    recordId: string,
    paperCommunicationRecordId: string
  ): void {
    const params = this.getUpdatedParamsFn(
      new EventPaperCommunicationParameterDto()
    );
    params.recordId = recordId;
    params.paperCommunicationRecordId = paperCommunicationRecordId;
    this.apiService.downloadPaperCommunication(params);
  }

  private deletePaperCommunication(
    recordId: string,
    paperCommunicationRecordId: string
  ): void {
    const params = this.getUpdatedParamsFn(
      new EventPaperCommunicationParameterDto()
    );
    params.recordId = recordId;
    params.paperCommunicationRecordId = paperCommunicationRecordId;
    this.apiService.deletePaperCommunication(params).subscribe(() => {
      this.showSuccess('Paper Communication Record Deleted Successfully.');
      this.onRefresh.emit();
    });
  }
}

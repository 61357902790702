<app-page-popup
  *ngIf="visible"
  title="Add New City"
  [width]="300"
  [height]="400"
  [visible]="visible"
  [buttons]="['Cancel', 'Ok']"
  (onButtonClick)="onButtonClick($event)"
  (onHidden)="onHidden()"
>
  <dx-form
    #editorForm
    width="100%"
    height="100%"
    [formData]="editorResult"
    [scrollingEnabled]="true"
  >
    <dxi-item [isRequired]="true">
      <dxo-label text="Country"></dxo-label>
      <div *dxTemplate>
        <dx-select-box
          valueExpr="countryId"
          displayExpr="countryName"
          [disabled]="!countries"
          [dataSource]="countries"
          [(value)]="editorResult.countryId"
          (onValueChanged)="onCountryChanged($event)"
        ></dx-select-box>
      </div>
    </dxi-item>
    <dxi-item [isRequired]="true">
      <dxo-label text="State"></dxo-label>
      <div *dxTemplate>
        <dx-select-box
          valueExpr="stateId"
          displayExpr="stateName"
          [disabled]="!statesOfCountry"
          [dataSource]="statesOfCountry"
          [(value)]="editorResult.stateId"
          (onValueChanged)="onStateChanged($event)"
        ></dx-select-box>
      </div>
    </dxi-item>
    <dxi-item
      dataField="cityName"
      [isRequired]="true"
      [editorOptions]="{
        disabled: !editorResult.stateId
      }"
    ></dxi-item>
  </dx-form>
</app-page-popup>

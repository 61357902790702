// Angular imports
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

// Dx imports
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

// Util imports
import { DateUtils } from '../../../_utils/date.utils';
import { DxWidgetUtils } from '../../../_utils/dx-widget.utils';
import { Utils } from '../../../_utils/utils';

// Component imports
import { BaseComponent } from '../../../_base/base.component';

// Constant imports
import { ActionConstants } from '../../../_constants/action.constants';
import { IconConstants } from '../../../_constants/icon.constants';

// Enum imports
import { ReferenceDateType } from '../../../_enums/reference-date-type.enum';

// Type imports
import {
  ActionMenuEvent,
  ActionMenuInfo
} from '../../../_types/action-menu-info';
import { EventContextInfo } from '../../../_types/event-context-info';
import { ScheduledCommunicationsService } from '../../../_types/scheduled-communications-service';

// Model imports
import { KeyValueResultDto } from '../../../_models/common/key-value-result.dto';
import { BaseEventNoticeParameterDto } from '../../../_models/event/base-event-notice-parameter.dto';
import { BaseEventParameterDto } from '../../../_models/event/base-event-parameter.dto';
import { EventEmailRecipientResultDto } from '../../../_models/event/event-email-recipient-result.dto';
import { EventScheduledCommunicationPeriodicitySummaryResultDto } from '../../../_models/event/scheduled-communications/event-scheduled-communication-periodicity-summary-result.dto';
import { EventScheduledCommunicationStatusParameterDto } from '../../../_models/event/scheduled-communications/event-scheduled-communication-status-parameter.dto';
import { EventScheduledCommunicationSummaryEditorResultDto } from '../../../_models/event/scheduled-communications/event-scheduled-communication-summary-editor-result.dto';
import { EventScheduledCommunicationSummaryResultDto } from '../../../_models/event/scheduled-communications/event-scheduled-communication-summary-result.dto';

@Component({
  selector: 'app-event-scheduled-communications-manager',
  templateUrl: './event-scheduled-communications-manager.component.html',
  styleUrls: ['./event-scheduled-communications-manager.component.scss']
})
export class EventScheduledCommunicationsManagerComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild('dataGrid', { static: false }) grid: DxDataGridComponent;

  @Input() isReadOnly: boolean;
  @Input() eventInfo: EventContextInfo;
  @Input() apiService: ScheduledCommunicationsService;
  @Output() onReady =
    new EventEmitter<EventScheduledCommunicationSummaryEditorResultDto>();

  iconPlus = IconConstants.PLUS;
  iconToggleExpand = IconConstants.EXPAND;

  showEditor = false;
  showMeetingEditor = false;
  showNoticeStatus = false;
  isExpanded = false;

  actionItems: Array<ActionMenuInfo>;
  recipientGroups: Array<KeyValueResultDto<number>>;
  referenceDateTypes: Array<KeyValueResultDto<number>>;
  editorResult: EventScheduledCommunicationSummaryEditorResultDto;
  communications: Array<EventScheduledCommunicationSummaryResultDto>;
  selectedRecord: EventScheduledCommunicationSummaryResultDto;
  noticeStatus: Array<EventEmailRecipientResultDto>;

  constructor(protected override readonly injector: Injector) {
    super(injector);
    this.isActionVisible = this.isActionVisible.bind(this);
  }

  ngOnInit(): void {
    this.organizationInfo = this.contextService.getOrganization();
    this.actionItems = [
      {
        id: ActionConstants.EDIT,
        name: ActionConstants.EDIT,
        icon: IconConstants.EDIT
      },
      {
        id: ActionConstants.DELETE,
        name: ActionConstants.DELETE,
        icon: IconConstants.DELETE
      }
    ];
    this.populateScheduledCommunicationEditor();
  }

  onToggleExpandAll(updateState: boolean): void {
    if (Utils.isTrue(updateState)) {
      this.isExpanded = Utils.isFalse(this.isExpanded);
    }
    this.iconToggleExpand = Utils.isFalse(this.isExpanded)
      ? IconConstants.EXPAND
      : IconConstants.COLLAPSE;
    if (DxWidgetUtils.hasInstance(this.grid)) {
      if (Utils.isTrue(this.isExpanded)) {
        this.grid.instance.expandAdaptiveDetailRow('');
      } else {
        this.grid.instance.collapseAdaptiveDetailRow();
      }
    }
  }

  onRefreshClick(): void {
    this.populateScheduledCommunicationEditor();
  }

  isActionVisible(
    e: ActionMenuEvent<EventScheduledCommunicationSummaryResultDto>
  ): boolean {
    switch (e.action.id) {
      case ActionConstants.EDIT:
        return (
          Utils.isTrue(this.editorResult.allowUpdate) &&
          Utils.isTrue(e.record.allowChange)
        );
      case ActionConstants.DELETE:
        return (
          Utils.isTrue(this.editorResult.allowDelete) &&
          Utils.isTrue(e.record.allowChange)
        );
      default:
        return false;
    }
  }

  onActionClick(
    e: ActionMenuEvent<EventScheduledCommunicationSummaryResultDto>
  ): void {
    if (Utils.equalsIgnoreCase(e.action.id, ActionConstants.EDIT)) {
      this.selectedRecord = e.record;
      this.showEditor = true;
    } else if (Utils.equalsIgnoreCase(e.action.id, ActionConstants.DELETE)) {
      this.messageService
        .showConfirmMessage(
          'Confirmation',
          'Are you sure you want to delete this record?',
          'danger'
        )
        .then((choice: boolean) => {
          if (Utils.isTrue(choice)) {
            this.deleteCommunication(e.record.recordId);
          }
        });
    }
  }

  onLinkCellClick(
    recordId: string,
    periodicity: EventScheduledCommunicationPeriodicitySummaryResultDto
  ): void {
    this.showNoticeStatus = false;
    const params = this.getUpdatedParameter(
      new EventScheduledCommunicationStatusParameterDto()
    );
    params.recordId = recordId;
    params.periodId = periodicity.periodId;
    this.apiService
      .getScheduledCommunicationStatus(params)
      .subscribe((results: Array<EventEmailRecipientResultDto>) => {
        this.noticeStatus = results;
        this.showNoticeStatus = true;
      });
  }

  onEditorSave(): void {
    this.onEditorCancel();
    this.populateScheduledCommunicationEditor();
  }

  onEditorCancel(): void {
    this.showEditor = false;
    this.showMeetingEditor = false;
    this.selectedRecord = null;
  }

  private populateScheduledCommunicationEditor(): void {
    const params = this.getUpdatedParameter(new BaseEventParameterDto());
    if (Utils.notNullOrEmpty(params.eventId)) {
      this.apiService
        .getScheduledCommunicationEditor(params)
        .subscribe(
          (result: EventScheduledCommunicationSummaryEditorResultDto) => {
            this.recipientGroups = result.recipientGroups;
            this.referenceDateTypes = result.referenceDateTypes;
            this.communications = result.communicationSummary || [];
            this.communications.forEach((r) => this.updateReferenceDate(r));
            this.editorResult = result;
            this.onToggleExpandAll(false);
            this.onReady.emit(result);
          }
        );
    }
  }

  private deleteCommunication(recordId: string): void {
    const params = this.getUpdatedParameter(new BaseEventNoticeParameterDto());
    params.recordId = recordId;
    this.apiService.deleteScheduledCommunication(params).subscribe(() => {
      this.showSuccess('Scheduled Communication Record Deleted Successfully.');
      this.populateScheduledCommunicationEditor();
    });
  }

  private updateReferenceDate(
    record: EventScheduledCommunicationSummaryResultDto
  ): void {
    record.referenceDate = record.referenceCustomDate;
    if (
      Utils.equals(record.referenceDateTypeId, ReferenceDateType.EventCloseDate)
    ) {
      record.referenceDate = this.eventInfo.resultPublishLocalDate;
    } else if (
      Utils.equals(
        record.referenceDateTypeId,
        ReferenceDateType.ParticipationEndDate
      )
    ) {
      record.referenceDate = this.eventInfo.participationEndLocalDate;
    } else if (
      Utils.equals(
        record.referenceDateTypeId,
        ReferenceDateType.ParticipationStartDate
      )
    ) {
      record.referenceDate = this.eventInfo.participationStartLocalDate;
    }
    record.periodicities.forEach((r) => {
      r.referenceDate = record.referenceDate;
      r.scheduledDate = DateUtils.removeDays(record.referenceDate, r.period);
    });
  }

  private getUpdatedParameter<T extends BaseEventParameterDto>(params: T): T {
    params.organizationId = this.organizationInfo.organizationId;
    params.entityId = this.eventInfo.entityId;
    params.eventId = this.eventInfo.eventId;
    return params;
  }
}

// Angular imports
import { Component, Injector, Input, OnInit } from '@angular/core';
import { Data } from '@angular/router';

// Component imports
import { BaseComponent } from '../../../_base/base.component';

@Component({
  selector: 'app-invalid-url',
  templateUrl: './invalid-url.component.html',
  styleUrls: ['./invalid-url.component.scss']
})
export class InvalidUrlComponent extends BaseComponent implements OnInit {
  @Input() message: string;

  defaultMessage = `You are using an invalid URL. Please check the URL and make sure it's complete as received in email.`;

  constructor(protected override readonly injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((r: Data) => {
      this.message = r['message'] || this.defaultMessage;
    });
  }
}

// Angular imports
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// 3rd party imports
import { plainToInstance } from 'class-transformer';

// Rxjs imports
import { map, Observable } from 'rxjs';

// Service imports
import { CachingService } from './caching.service';

// Constant imports
import { TimerConstants } from '../_constants/timer.constants';
import { UrlConstants } from '../_constants/url.constants';

// Model imports
import { BaseCommunityParameterDto } from '../_models/community/base-community-parameter.dto';
import { CommunitySelectorResultDto } from '../_models/community/community-selector-result.dto';
import { BaseOrganizationParameterDto } from '../_models/organization/base-organization-parameter.dto';

@Injectable({ providedIn: 'root' })
export class CommunityService {
  constructor(
    protected readonly httpClient: HttpClient,
    protected readonly cachingService: CachingService
  ) {}

  getCommunities(
    params: BaseOrganizationParameterDto
  ): Observable<Array<CommunitySelectorResultDto>> {
    const url = `${UrlConstants.COMMUNITY_BASE_URL}/selector`;
    const options = this.cachingService.register(
      url,
      TimerConstants.inMinutes(30),
      params
    );
    return this.httpClient
      .get<Array<any>>(url, options)
      .pipe(
        map((plain: Array<any>) =>
          plainToInstance(CommunitySelectorResultDto, plain)
        )
      );
  }

  getCommunity(
    params: BaseCommunityParameterDto
  ): Observable<CommunitySelectorResultDto> {
    const url = `${UrlConstants.COMMUNITY_BASE_URL}`;
    const options = this.cachingService.register(
      url,
      TimerConstants.inMinutes(30),
      params
    );
    return this.httpClient
      .get(url, options)
      .pipe(
        map((plain: any) => plainToInstance(CommunitySelectorResultDto, plain))
      );
  }
}

<div class="cv-fullsize-content cv-center-content">
  <div
    class="cv-invalid-url-content"
    [ngClass]="{
      'cv-invalid-url-content-md': !isLarge && !isXSmall,
      'cv-invalid-url-content-sm': isXSmall
    }"
  >
    <div *ngIf="!isXSmall" class="cv-invalid-url-image"></div>
    <h1>Oops!</h1>
    <h2>Invalid Participation Link</h2>
    <p>
      Sorry but the page you are looking for does not exist.
      <span [innerHTML]="message | safeHtml"></span>
    </p>
  </div>
</div>

// Angular imports
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

// Util imports
import { Utils } from '../../../_utils/utils';

// Component imports
import { BaseComponent } from '../../../_base/base.component';

// Service imports
import { PropertyService } from '../../../_services/property.service';

// Type imports
import { SelectionGridEvent } from '../../../_types/selection-grid-event';

// Model imports
import { KeyValueResultDto } from '../../../_models/common/key-value-result.dto';
import { BaseCommunityParameterDto } from '../../../_models/community/base-community-parameter.dto';
import { CommunityPropertyResultDto } from '../../../_models/property/community-property-result.dto';

@Component({
  selector: 'app-property-selector',
  templateUrl: './property-selector.component.html',
  styleUrls: ['./property-selector.component.scss']
})
export class PropertySelectorComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input() readOnly = false;
  @Input() isRequired = false;
  @Input() validationGroup: string;
  @Input() organizationId: number;
  @Input() entityId: number;
  @Input() selectedKeys: Array<number>;

  @Output() onSelectionChanged = new EventEmitter<
    SelectionGridEvent<CommunityPropertyResultDto, number>
  >();

  properties: Array<CommunityPropertyResultDto>;
  propertyTypes: Array<KeyValueResultDto<number>> = [];

  private readonly propertyService: PropertyService;

  constructor(protected override readonly injector: Injector) {
    super(injector);
    this.propertyService = injector.get(PropertyService);
  }

  ngOnInit(): void {
    this.onInit('PropertySelectorComponent');
    this.populatePropertyTypes();
    this.populateProperties();
  }

  ngOnDestroy(): void {
    this.onDestroy('PropertySelectorComponent');
  }

  onContentReady(): void {
    if (Utils.notNullOrEmpty(this.selectedKeys)) {
      const records = this.properties.filter((r) =>
        Utils.isInList(r.propertyId, this.selectedKeys)
      );
      const e: SelectionGridEvent<CommunityPropertyResultDto, number> = {
        selectedKeys: this.selectedKeys,
        selectedRows: records
      };
      this.onSelectionChanged.emit(e);
    }
  }

  onValueChanged(
    e: SelectionGridEvent<CommunityPropertyResultDto, number>
  ): void {
    this.selectedKeys = e.selectedKeys;
    this.onSelectionChanged.emit(e);
  }

  private populatePropertyTypes(): void {
    if (
      Utils.notNullOrEmpty(this.organizationId) &&
      Utils.notNullOrEmpty(this.entityId)
    ) {
      const params = new BaseCommunityParameterDto();
      params.organizationId = this.organizationId;
      params.entityId = this.entityId;
      this.propertyService
        .getPropertyTypes(params)
        .subscribe((result: Array<KeyValueResultDto<number>>) => {
          this.propertyTypes = result;
        });
    }
  }

  private populateProperties(): void {
    if (
      Utils.notNullOrEmpty(this.organizationId) &&
      Utils.notNullOrEmpty(this.entityId)
    ) {
      const params = new BaseCommunityParameterDto();
      params.organizationId = this.organizationId;
      params.entityId = this.entityId;
      this.propertyService
        .getProperties(params)
        .subscribe((results: Array<CommunityPropertyResultDto>) => {
          this.properties = results;
        });
    }
  }

  protected override updateOrganizationInfo(): void {
    super.updateOrganizationInfo();
    if (
      Utils.notNullAndDefined(this.organizationInfo) &&
      Utils.notEquals(this.organizationId, this.organizationInfo.organizationId)
    ) {
      this.organizationId = this.organizationInfo.organizationId;
      this.populateProperties();
    }
  }
}

<dx-box width="100%" height="100%" direction="col">
  <dxi-item baseSize="auto" [visible]="!!title">
    <div *dxTemplate class="cv-event-notes-title-text">
      <span *ngIf="!!title" [innerHTML]="title | safeHtml"></span>
    </div>
  </dxi-item>
  <dxi-item [ratio]="1">
    <div *dxTemplate [ngClass]="{ 'cv-vbox-item': !!title }">
      <as-split
        [direction]="direction"
        [gutterSize]="6"
        (dragEnd)="onSplitDragEnd($event)"
      >
        <as-split-area [order]="orders[0]" [size]="sizes[0]">
          <div class="cv-fullsize-content">
            <dx-data-grid
              appDataGridFeatures
              width="100%"
              height="100%"
              [dataSource]="eventNotes"
              [gridFeatures]="{
                wordWrapEnabled: true
              }"
            >
              <dxi-column
                width="50%"
                dataField="notes"
                dataType="string"
                caption="Notes"
              ></dxi-column>
              <dxi-column
                width="30%"
                dataField="createdDate"
                dataType="datetime"
                caption="Added On"
                [format]="dateTimeFormat"
              ></dxi-column>
              <dxi-column
                width="20%"
                dataField="createdBy.name"
                dataType="string"
                caption="Added By"
              ></dxi-column>
            </dx-data-grid>
          </div>
        </as-split-area>
        <as-split-area [order]="orders[1]" [size]="sizes[1]">
          <div class="cv-fullsize-content">
            <dx-text-area
              width="100%"
              height="100%"
              placeholder="Enter new notes here"
              [(value)]="value"
              (valueChange)="valueChange.emit(value)"
            ></dx-text-area>
          </div>
        </as-split-area>
      </as-split>
    </div>
  </dxi-item>
</dx-box>

// Angular imports
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';

// Dx imports
import { DxFormComponent } from 'devextreme-angular/ui/form';

// Util imports
import { DxWidgetUtils } from '../../../_utils/dx-widget.utils';
import { Utils } from '../../../_utils/utils';

// Constant imports
import { ActionConstants } from '../../../_constants/action.constants';

@Component({
  selector: 'app-reason-editor',
  templateUrl: './reason-editor.component.html',
  styleUrls: ['./reason-editor.component.scss']
})
export class ReasonEditorComponent {
  @ViewChild('editorForm', { static: false }) editorForm: DxFormComponent;

  @Input() title: string;
  @Input() showContent = false;
  @Output() onOk = new EventEmitter<string>();
  @Output() onCancel = new EventEmitter<void>();

  reasonText: string;

  onReasonAction(name: string): void {
    if (
      Utils.equalsIgnoreCase(name, ActionConstants.OK) &&
      DxWidgetUtils.isFormValid(this.editorForm)
    ) {
      this.onOk.emit(this.reasonText);
    } else if (Utils.equalsIgnoreCase(name, ActionConstants.CANCEL)) {
      this.onCancel.emit();
    }
  }
}

// Angular imports
import { Component, EventEmitter, Input, Output } from '@angular/core';

// Util imports
import { Utils } from '../../../_utils/utils';

// Constant imports
import { IconConstants } from '../../../_constants/icon.constants';

// Model imports
import { CitySelectorResultDto } from '../../../_models/geolocation/city-selector-result.dto';

@Component({
  selector: 'app-city-text-box',
  templateUrl: './city-text-box.component.html',
  styleUrls: ['./city-text-box.component.scss']
})
export class CityTextBoxComponent {
  @Input() readOnly = false;
  @Input() isRequired = false;
  @Input() validationGroup: string;
  @Input() cityId: number;
  @Input() stateId: number;
  @Input() cityName: string;
  @Output() cityIdChange = new EventEmitter<number>();
  @Output() stateIdChange = new EventEmitter<number>();
  @Output() cityNameChange = new EventEmitter<string>();

  iconLookup = IconConstants.SEARCH;

  showCitySelector = false;

  constructor() {
    this.onCityLookupClick = this.onCityLookupClick.bind(this);
  }

  onCityLookupClick(): void {
    this.showCitySelector = true;
  }

  onCitySearch(results: Array<CitySelectorResultDto>): void {
    if (Utils.notNullOrEmpty(results)) {
      if (Utils.equals(results.length, 1)) {
        this.onCitySelect(results.at(0));
      } else if (
        (Utils.isNullOrUndefined(this.cityId) &&
          Utils.isNullOrUndefined(this.stateId)) ||
        Utils.notInList(
          this.cityId,
          results.map((r) => r.cityId)
        )
      ) {
        this.onCityLookupClick();
      }
    }
  }

  onCitySelect(rec: CitySelectorResultDto): void {
    this.cityId = rec.cityId;
    this.cityName = rec.cityName;
    this.stateId = rec.stateId;
    this.cityIdChange.emit(rec.cityId);
    this.cityNameChange.emit(rec.cityName);
    this.stateIdChange.emit(rec.stateId);
    this.showCitySelector = false;
  }

  onCityClose(): void {
    if (
      Utils.isNullOrUndefined(this.cityId) &&
      Utils.isNullOrUndefined(this.stateId) &&
      Utils.notNullOrEmpty(this.cityName)
    ) {
      this.cityName = Utils.emptyText();
    }
    this.showCitySelector = false;
  }
}

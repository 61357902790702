// Angular imports
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

// Util imports
import { Utils } from '../../../_utils/utils';

// Component imports
import { BaseComponent } from '../../../_base/base.component';

// Service imports
import { CommunityService } from '../../../_services/community.service';

// Type imports
import { SelectionGridEvent } from '../../../_types/selection-grid-event';

// Model imports
import { CommunitySelectorResultDto } from '../../../_models/community/community-selector-result.dto';
import { BaseOrganizationParameterDto } from '../../../_models/organization/base-organization-parameter.dto';

@Component({
  selector: 'app-community-selector',
  templateUrl: './community-selector.component.html',
  styleUrls: ['./community-selector.component.scss']
})
export class CommunitySelectorComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input() readOnly = false;
  @Input() isRequired = false;
  @Input() validationGroup: string;
  @Input() organizationId: number;
  @Input() selectedKeys: Array<number>;
  @Output() onSelectionChanged = new EventEmitter<
    SelectionGridEvent<CommunitySelectorResultDto, number>
  >();

  communities: Array<CommunitySelectorResultDto>;

  constructor(
    protected override readonly injector: Injector,
    private readonly communityService: CommunityService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.onInit('CommunitySelectorComponent');
    this.populateCommunities();
  }

  ngOnDestroy(): void {
    this.onDestroy('CommunitySelectorComponent');
  }

  onContentReady(): void {
    if (Utils.notNullOrEmpty(this.selectedKeys)) {
      const records = this.communities.filter((r) =>
        Utils.isInList(r.id, this.selectedKeys)
      );
      const e: SelectionGridEvent<CommunitySelectorResultDto, number> = {
        selectedKeys: this.selectedKeys,
        selectedRows: records
      };
      this.onSelectionChanged.emit(e);
    }
  }

  onValueChanged(
    e: SelectionGridEvent<CommunitySelectorResultDto, number>
  ): void {
    this.selectedKeys = e.selectedKeys;
    this.onSelectionChanged.emit(e);
  }

  private populateCommunities(): void {
    if (Utils.notNullOrEmpty(this.organizationId)) {
      const params = new BaseOrganizationParameterDto();
      params.organizationId = this.organizationId;
      this.communityService
        .getCommunities(params)
        .subscribe((results: Array<CommunitySelectorResultDto>) => {
          this.communities = results;
        });
    }
  }

  protected override updateOrganizationInfo(): void {
    super.updateOrganizationInfo();
    if (
      Utils.notNullAndDefined(this.organizationInfo) &&
      Utils.notEquals(this.organizationId, this.organizationInfo.organizationId)
    ) {
      this.organizationId = this.organizationInfo.organizationId;
      this.populateCommunities();
    }
  }
}

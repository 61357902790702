<app-page-popup
  *ngIf="editorResult"
  width="50%"
  height="80%"
  [title]="title"
  [buttons]="['Custom']"
  [customButtons]="actionButtons"
  [visible]="true"
  (onHidden)="onClose.emit()"
  (onButtonClick)="onActionClick($event)"
>
  <dx-form
    #editorForm
    width="100%"
    height="100%"
    [readOnly]="isReadOnly"
    [scrollingEnabled]="true"
    [validationGroup]="validationGroup"
    [formData]="editorParam"
  >
    <dxi-item itemType="group" [colCount]="3" [visible]="!!editorResult.status">
      <dxi-item>
        <dxo-label
          [text]="editorResult.isCompleted ? 'Created By' : 'Scheduled By'"
        ></dxo-label>
        <div *dxTemplate>
          <app-colored-label
            [weight]="600"
            [text]="editorResult.createdByText"
          ></app-colored-label>
        </div>
      </dxi-item>
      <dxi-item>
        <dxo-label
          [text]="editorResult.isCompleted ? 'Created Date' : 'Scheduled Date'"
        ></dxo-label>
        <div *dxTemplate>
          <app-colored-label
            [weight]="600"
            [text]="editorResult.createdDateText"
          ></app-colored-label>
        </div>
      </dxi-item>
      <dxi-item>
        <dxo-label text="Status"></dxo-label>
        <div *dxTemplate>
          <app-status-cell-template
            [status]="editorResult.status"
          ></app-status-cell-template>
        </div>
      </dxi-item>
    </dxi-item>
    <dxi-item
      dataField="reportFilename"
      [isRequired]="true"
      [visible]="editorResult.allowGenerate"
    >
      <dxo-label text="Filename"></dxo-label>
    </dxi-item>
    <dxi-item
      dataField="notes"
      editorType="dxTextArea"
      [editorOptions]="{
        height: 100,
        placeholder: 'Enter Additional Notes'
      }"
      [visible]="editorResult.allowGenerate"
    >
      <dxo-label text="Notes"></dxo-label>
    </dxi-item>
    <dxi-item
      itemType="group"
      [colCount]="10"
      [visible]="editorResult.allowGenerate"
    >
      <dxi-item [colSpan]="10">
        <dxo-label [visible]="false"></dxo-label>
        <div *dxTemplate class="cv-1rx">
          <span>Upload Additional Documents</span>
        </div>
      </dxi-item>
      <ng-container
        *ngFor="let docFile of additionalDocuments; let idx = index"
      >
        <dxi-item [colSpan]="8">
          <dxo-label [visible]="false"></dxo-label>
          <div *dxTemplate>
            <app-file-input
              [fileInfo]="docFile"
              [readOnly]="isReadOnly"
              [deleteEnabled]="false"
              [allowedExtensions]="['.pdf']"
            ></app-file-input>
          </div>
        </dxi-item>
        <dxi-item [colSpan]="2">
          <dxo-label [visible]="false"></dxo-label>
          <div *dxTemplate>
            <dx-toolbar>
              <dxi-item location="before">
                <div *dxTemplate>
                  <dx-button
                    type="danger"
                    stylingMode="outlined"
                    hint="Remove Document"
                    [icon]="iconRemove"
                    [visible]="
                      additionalDocuments.length > 1 || !!docFile.filename
                    "
                    [disabled]="isReadOnly"
                    (onClick)="onRemoveFileClick(idx)"
                  ></dx-button>
                </div>
              </dxi-item>
            </dx-toolbar>
          </div>
        </dxi-item>
      </ng-container>
    </dxi-item>
    <dxi-item [visible]="editorResult.allowGenerate">
      <dxo-label [visible]="false"></dxo-label>
      <div *dxTemplate>
        <dx-toolbar>
          <dxi-item location="before">
            <div *dxTemplate>
              <dx-button
                type="default"
                stylingMode="outlined"
                text="Add Document"
                hint="Add New Document"
                [icon]="iconPlus"
                [visible]="!isReadOnly"
                [disabled]="additionalDocuments.length > 5"
                (onClick)="onAddFileClick(additionalDocuments.length)"
              ></dx-button>
            </div>
          </dxi-item>
        </dx-toolbar>
      </div>
    </dxi-item>
  </dx-form>
</app-page-popup>

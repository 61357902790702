<dx-text-box
  [buttons]="[
    {
      name: 'selector',
      location: 'after',
      options: {
        type: 'default',
        stylingMode: 'text',
        hint: 'City Lookup',
        icon: iconLookup,
        onClick: onCityLookupClick
      }
    }
  ]"
  [readOnly]="readOnly"
  [(value)]="cityName"
>
  <dx-validator *ngIf="isRequired" [validationGroup]="validationGroup">
    <dxi-validation-rule
      type="required"
      message="City selection is required"
    ></dxi-validation-rule>
  </dx-validator>
</dx-text-box>

<app-city-selector
  [visible]="showCitySelector"
  [searchTerm]="cityName"
  [selectedId]="cityId"
  (onSearch)="onCitySearch($event)"
  (onSelect)="onCitySelect($event)"
  (onClose)="onCityClose()"
></app-city-selector>

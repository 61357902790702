import { Transform } from 'class-transformer';
import { GeoLocationUtils } from '../../_utils/geo-location.utils';
import { BaseOrganizationParameterDto } from '../organization/base-organization-parameter.dto';

export class CommunityEntityParameterDto extends BaseOrganizationParameterDto {
  entityId: number;
  communityTypeId: number;
  masterCommunityId: number;
  managerUserId: number;
  stateId: number;
  @Transform(GeoLocationUtils.toJsonCityId, { toPlainOnly: true })
  cityId: number;
  latitude: number;
  longitude: number;
  residentialUnitCount: number;
  commercialUnitCount: number;

  legalName: string;
  referredName: string;
  address1: string;
  address2: string;
  cityName: string;
  postalCode: string;
  isNonprofit: boolean;
}
